<form *ngIf="offer$ | async as OfferDetails; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div class="d-flex">
                <h2 >{{formGroup.controls.Detail.controls.Name.value == '' ? "Promo" :
                    formGroup.controls.Detail.controls.Name.value}}
                </h2>
                <button *ngIf="offerId !=='0' && canEdit() == true" (click)="deleteRecord(OfferDetails?.Detail?.OfferVariantName)"  class="version" mat-button> {{formGroup.controls.Detail.controls.OfferVariantName.value}} <fa-icon *ngIf=" OfferDetails?.Detail?.OfferVariantName !== 'Base'" style="color: mediumblue;" [icon]="['fas', 'trash']"></fa-icon>  </button>
                <button  (click)="navigateChildOffers()" mat-button type="button" color="accent"  *ngIf="offerId !=='0' && versionsCount > 1" class="view-all">View All Variants ({{ versionsCount }})</button>
            </div>


            <div fxFlex="50" class="d-flex flex-end header-card-actions">
                <div class="comments">
                    <div *ngIf="OfferDetails?.CommentsCount" style="padding-right: 30px;">
                        <div id={{OfferDetails.DomainId}} (click)="openDialog($event,OfferDetails)" class="comment-dialog">
                
                            <i class="fa fa-comment" aria-hidden="true" style="font-size: 1.5rem;"></i>
                            <span class="comments-count">{{OfferDetails.CommentsCount}}</span>
                        </div>
                    </div>
                    <div *ngIf="OfferDetails?.CommentsCount==0" style="padding-right: 30px;">
                        <span id={{OfferDetails.DomainId}} (click)="openDialog($event,OfferDetails)" class="comment-dialog">
                            <i class="fa fa-comment-o" aria-hidden="true" style="font-size: 1.5rem;"></i>
                        </span>
                    </div>
                </div>
                <div [ngClass]="{'mydisable': formGroup.invalid}" *ngIf="offerId != '0'" style="top: 0; position: relative; " [ngStyle]="{'top': canEdit() ? '0': '17px'}">
                    <span class="status-btns status {{formGroup.controls.Detail.controls.Status.value === '0' ? 'statusPENDING' :  'status' + formGroup.controls.Detail.controls.Status.value}}">
                        {{(formGroup.controls.Detail.controls.Status && formGroup.controls.Detail.controls.Status.value === '0') ? 'PENDING' : formGroup.controls.Detail.controls.Status.value }} <mat-icon *ngIf="canEdit()" [matMenuTriggerFor]="canEdit() ? menu: null" class="icon">arrow_drop_down</mat-icon> </span>
                </div>
                <mat-menu #menu="matMenu">
                    <button type="button" mat-menu-item class="statusPENDING" (click)="updateTableStatus(1)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'PENDING'">PENDING</button>
                    <button type="button" class="statusAPPROVED" mat-menu-item (click)="updateTableStatus(2)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'APPROVED'">APPROVED</button>
                    <button type="button" class="statusSUPPRESSED" mat-menu-item (click)="updateTableStatus(3)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'SUPPRESSED'">SUPPRESSED</button>
                </mat-menu>
                <mat-card-actions fxLayout="row" [fxLayoutAlign]="offerId != '0'?'space-between':'end'" [ngClass]="{'flex-row-reverse': canEdit() == false}">
                    <div class="btn-width">
                        <button class="btn-right" mat-stroked-button type="button" (click)="cancel()">
                            <span *ngIf="!canEdit()">Close</span>
                            <span *ngIf="canEdit()">Cancel</span>
                        </button>
                        <button *ngIf="canEdit()" mat-raised-button type="button" (click)="save()" [disabled]="formGroup.invalid" color="accent">
                            Save
                        </button>
                    </div>
                </mat-card-actions>
            </div>
</div>
            <div formGroupName="Detail">
                <mat-accordion class="OfferDetails" multi>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Promo Details</mat-panel-title>
                            <mat-panel-description>Copy, Dates and Tags</mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                            <mat-card appearance="outlined" fxFlex="20" fxFlexFill fxLayoutAlign="center" class="marginTop mat-card-offer">
                                <!-- <img mat-card-md-image *ngIf="OfferDetails.FileName" src="{{assetsUrl$ | async}}/{{OfferDetails.FileName}}" class="product-image" alt="offer image">
                                <img mat-card-md-image *ngIf="!OfferDetails.FileName" src="../.././assets/images/default-image.png" class="product-image" alt="offer image"> -->
                                <ng-container *ngIf="assetsUrl$ | async as assetsUrl">
                                    <img *ngIf="OfferDetails?.FileName" mat-card-md-image
                                        [src]="getImageSrc(assetsUrl, OfferDetails)" alt="Offer image">
                                </ng-container>
                                <img mat-card-md-image *ngIf="!OfferDetails.FileName"
                                    src="./assets/images/default-image.png" alt="Offer image">
                            </mat-card>
                            <div fxLayout="column" fxFlex="auto" class="marginTop">
                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>Promo Name</mat-label>
                                    <input [readonly]="!canEdit()" matInput type="text" formControlName="Name" />
                                    <mat-error
                                        *ngIf="formGroup.controls.Detail.controls.Name.hasError('required')">
                                        Promo Name is required
                                    </mat-error>
                                    <mat-error *ngIf="formGroup.controls.Detail.controls.Name.status=='INVALID'">
                                        {{formGroup.controls.Detail.controls.Name.errors!['maxlength'] && 'Maximum
                                        length can be
                                        '+formGroup.controls.Detail.controls.Name.errors!['maxlength']['requiredLength']+'.
                                        '}}
                                        {{formGroup.controls.Detail.controls.Name.errors!['pattern'] && 'Offer Name
                                        may only contain alphanumeric and .,;-#&*\'/ characters. '}}
                                    </mat-error>
                                </mat-form-field>


                                <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                                    <mat-form-field appearance="outline" fxFlex>
                                        <mat-label>Promo Start Date</mat-label>
                                        <input [disabled]="canEdit() == false" matInput [matDatepicker]="startDatePicker" formControlName="StartDate">
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.StartDate.hasError('required')">
                                            Start date is required.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.StartDate.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.StartDate.errors!['matDatepickerMax']
                                            && 'Start Date exceeded maximum year.'}}
                                        </mat-error>
                                        <mat-datepicker-toggle [disabled]="!canEdit()" matSuffix [for]="startDatePicker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker [disabled]="!canEdit()" #startDatePicker></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <mat-label>Promo End Date</mat-label>
                                        <input [disabled]="!canEdit()" matInput [matDatepicker]="endDatePicker" formControlName="EndDate">
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.EndDate.hasError('required')">
                                            End date is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.EndDate.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.EndDate.errors!['matDatepickerMax'] &&
                                            'End Date exceeded maximum year.'}}
                                        </mat-error>
                                        <mat-datepicker-toggle [disabled]="!canEdit()" matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker [disabled]="!canEdit()" #endDatePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Headline</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="Headline" />
                                <!-- <mat-error *ngIf="formGroup.controls.Detail.controls.Headline.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.Headline.errors!['pattern'] && 'Headline may
                                    only
                                    contain alphanumeric and .,;-#&*\'/ characters. '}}
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Body Copy</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="BodyCopy" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.BodyCopy.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.BodyCopy.errors!['maxlength'] && 'Maximum
                                    length
                                    can be
                                    '+formGroup.controls.Detail.controls.BodyCopy.errors!['maxlength']['requiredLength']+'.
                                    '}}
                                    {{formGroup.controls.Detail.controls.BodyCopy.errors!['pattern'] && 'Body Copy may
                                    only contain alphanumeric and .,;-#&*\'/ characters. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Disclaimer</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="Disclaimer" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.Disclaimer.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.Disclaimer.errors!['maxlength'] && 'Maximum
                                    length can be
                                    '+formGroup.controls.Detail.controls.Disclaimer.errors!['maxlength']['requiredLength']+'.
                                    '}}
                                    {{formGroup.controls.Detail.controls.Disclaimer.errors!['pattern'] && 'Disclaimer
                                    may
                                    only contain alphanumeric and .,;-#&*\'/ characters. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Comments</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="ClientComment" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientComment.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.ClientComment.errors!['maxlength'] && 'Maximum
                                    length can be
                                    '+formGroup.controls.Detail.controls.ClientComment.errors!['maxlength']['requiredLength']+'.
                                    '}}
                                    {{formGroup.controls.Detail.controls.ClientComment.errors!['pattern'] && 'Comment
                                    may
                                    only contain alphanumeric and .,;-#&*\'/ characters. '}}
                                </mat-error>
                            </mat-form-field>

                            <div fxFlex>
                                <div fxFlex="50">
                                    <div style="max-height: 275px; overflow: auto;"
                                        *ngIf="additionalTexts && additionalTexts.controls && additionalTexts.controls.length > 0"
                                        formArrayName="AdditionalText">
                                        <label>Custom Text</label>
                                        <ng-container
                                            *ngFor="let additionalText of additionalTexts.controls; let i = index">
                                            <div [formGroupName]="i">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <input [readonly]="!canEdit()" matInput placeholder="Enter Text"
                                                        formControlName="addtionalTextDetail">
                                                    <mat-error *ngIf="additionalText.status=='INVALID'">
                                                        {{additionalTexts.controls[i].get('addtionalTextDetail')?.errors?.['maxlength']
                                                        && 'Maximum length can be '+
                                                        additionalTexts.controls[i].get('addtionalTextDetail')?.errors?.['maxlength']['requiredLength']+'.'}}
                                                        {{additionalTexts.controls[i].get('addtionalTextDetail')?.errors?.['pattern']
                                                        && 'Comment may only contain alphanumeric and .,;-#&*\'/
                                                        characters. '}}
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-icon *ngIf="canEdit()" style="margin: 15px;" class="delete-btn"
                                                    (click)="deleteAddtionalText(i)">delete_forever</mat-icon>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <button *ngIf="canEdit()" type="button" style="margin: 10px;" mat-button
                                        (click)="addAdditionalText('')">
                                        <mat-icon class="add-course-btn">add</mat-icon>
                                        Add Custom Text
                                    </button>
                                </div>
                                <div fxFlex="50" style="margin-left: 3.5rem;">
                                    <div style="max-height: 275px; overflow: auto;">
                                        <label>Target Url</label>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <input [readonly]="!canEdit()" matInput type="text" formControlName="TargetURL" />
                                                <mat-error *ngIf="formGroup.controls.Detail.controls.TargetURL.status=='INVALID'">
                                                    {{formGroup.controls.Detail.controls.TargetURL.errors!['maxlength'] && 'Maximum
                                                    length
                                                    can be
                                                    '+formGroup.controls.Detail.controls.TargetURL.errors!['maxlength']['requiredLength']+'.
                                                    '}}
                                                    {{formGroup.controls.Detail.controls.TargetURL.errors!['pattern'] && 'TargetURL
                                                    may
                                                    only
                                                    contain alphanumeric characters. '}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Alternate Text</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="AlternateText" />
                            </mat-form-field> 

                            <div fxFlex="100">
                                <app-pr1-offer-tags
                                    [placeholder]="'Associate Tag...'"
                                    [label]="'Offer Tags'"
                                    [maxTagsLength]="5"
                                    [selectedTags]="OfferTagIds"
                                    (tagRemoved)="removeTag($event)"
                                    (tagAdded)="addTag($event)"
                                    [offerTagsFormControl]="tagCtrl"
                                    (tagSelectedEmitter)="tagSelected($event)">
                                </app-pr1-offer-tags>
                            </div>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Badge</mat-label>
                                <mat-select [disabled]="canEdit() == false" formControlName="BadgeAssetId">
                                    <mat-option *ngFor="let item of assetsforBadge" [value]="item?.Id" class="badge-dropdown">
                                        <img *ngIf="item?.FileName && assetsUrl$ | async as assetsUrl"
                                            class="badge-option-image" [src]="getImageSrc(assetsUrl, item)"
                                            alt="Offer image">
                                       <span>{{item?.AssetName}}</span>
                                    </mat-option>
                                    <mat-option [value]="noneValue">None</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33" *ngIf="offerId != '0'">
                                <mat-label>Id</mat-label>
                                <input [readonly]="!canEdit()" matInput formControlName="Id" readonly>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Client key</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="ClientKey" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('required')">
                                    Client key is required
                                </mat-error>
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.ClientKey.errors!['maxlength'] && 'Maximum
                                    length
                                    can be
                                    '+formGroup.controls.Detail.controls.ClientKey.errors!['maxlength']['requiredLength']+'.
                                    '}}
                                    {{formGroup.controls.Detail.controls.ClientKey.errors!['pattern'] && 'Client Key may
                                    only contain alphanumeric and _-#&*\'/ characters. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Rank</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="Rank" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.Rank.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.Rank.errors!['max'] && 'Maximum number can be '
                                    + formGroup.controls.Detail.controls.Rank.errors!['max']['max'] + '. '}}
                                    {{formGroup.controls.Detail.controls.Rank.errors!['min'] && 'Minimum required number
                                    can be' + formGroup.controls.Detail.controls.Rank.errors!['min']['min'] + '. '}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
        </mat-card-content>
        <mat-card-actions class="card-footer-actions"  fxLayout="row" [fxLayoutAlign]="offerId != '0' && OfferDetails?.Detail?.OfferVariantName == 'Base' ?'space-between':'end'" [ngClass]="{'flex-row-reverse': canEdit() == false}">
            <button mat-raised-button type="button" color="warn" *ngIf="(offerId != '0' && OfferDetails?.Detail?.OfferVariantName == 'Base') && canEdit()" (click)="deleteBaseRecord()">
                Delete
            </button>
            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" fxLayoutAlign="end">
                <div [ngClass]="{'mydisable': formGroup.invalid}" *ngIf="offerId != '0'" style="top: 0; position: relative; " [ngStyle]="{'top': canEdit() ? '0': '17px'}">
                    <span class="status-btns status {{formGroup.controls.Detail.controls.Status.value === '0' ? 'statusPENDING' :  'status' + formGroup.controls.Detail.controls.Status.value}}">
                        {{(formGroup.controls.Detail.controls.Status && formGroup.controls.Detail.controls.Status.value === '0') ? 'PENDING' : formGroup.controls.Detail.controls.Status.value }} <mat-icon *ngIf="canEdit()" [matMenuTriggerFor]="canEdit() ? menu: null" class="icon">arrow_drop_down</mat-icon> </span>
                </div>
                <mat-menu #menu="matMenu">
                    <button type="button" mat-menu-item class="statusPENDING" (click)="updateTableStatus(1)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'PENDING'">PENDING</button>
                    <button type="button" class="statusAPPROVED" mat-menu-item (click)="updateTableStatus(2)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'APPROVED'">APPROVED</button>
                    <button type="button" class="statusSUPPRESSED" mat-menu-item (click)="updateTableStatus(3)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'SUPPRESSED'">SUPPRESSED</button>
                </mat-menu>
                <button class="btn-right" mat-stroked-button type="button" (click)="cancel()">
                    <span *ngIf="canEdit()">Cancel</span>
                    <span *ngIf="!canEdit()">Close</span>
                </button>
                <button *ngIf="canEdit()" mat-raised-button type="button" (click)="save()"
                    [disabled]="formGroup.invalid" color="accent">
                    Save
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
