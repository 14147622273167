<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
  <form [formGroup]="form">
    <div formArrayName="versions">
      <mat-checkbox *ngIf="canEdit() && viewModel && viewModel.offers && viewModel.offers.length > 0" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
      [indeterminate]="selection.hasValue() && !isAllSelected()">Select All</mat-checkbox>
      <div class="grid" *ngIf="viewModel.offers.length">        
        <mat-card appearance="outlined" *ngFor="let offers of viewModel.offers; index as i">
          <mat-card-content>
            <span class="rank" *ngIf="getActiveOffer(offers).Rank">Rank : {{ getActiveOffer(offers).Rank }}</span>
            <div class="offer-status">
              <span *ngIf="canEdit()" [matMenuTriggerFor]="menu" class="ellipsis-3-line {{
                offers &&
                getActiveOffer(offers) &&
                getActiveOffer(offers).Status &&
                getActiveOffer(offers).Status.toString() === '0'
                    ? 'statusPENDING'
                    : 'status' + getActiveOffer(offers).Status.toString()
                }}">{{
                offers &&
                getActiveOffer(offers) &&
                getActiveOffer(offers).Status &&
                getActiveOffer(offers).Status.toString() === "0"
                ? "PENDING"
                : getActiveOffer(offers).Status
                }}
                <mat-icon class="icon">arrow_drop_down</mat-icon>
              </span>

              <span *ngIf="!canEdit()" class="ellipsis-3-line {{
                offers &&
                getActiveOffer(offers) &&
                getActiveOffer(offers).Status &&
                getActiveOffer(offers).Status.toString() === '0'
                    ? 'statusPENDING'
                    : 'status' + getActiveOffer(offers).Status.toString()
                }}">{{
                offers &&
                getActiveOffer(offers) &&
                getActiveOffer(offers).Status &&
                getActiveOffer(offers).Status.toString() === "0"
                ? "PENDING"
                : getActiveOffer(offers).Status
                }}
              </span>
            </div>
            <mat-menu #menu="matMenu" class="status-menu">
              <button type="button" mat-menu-item class="statusAPPROVED" [disabled]="getActiveOffer(offers).Status === 'APPROVED'" (click)="updateTableStatus(2, getActiveOffer(offers))">
               APPROVED
              </button>
              <button type="button" mat-menu-item class="statusPENDING" [disabled]="getActiveOffer(offers).Status === 'PENDING'" (click)="updateTableStatus(1, getActiveOffer(offers))">
                PENDING
              </button>
              <button type="button" mat-menu-item class="statusSUPPRESSED" [disabled]="getActiveOffer(offers).Status === 'SUPPRESSED'" (click)="updateTableStatus(3, getActiveOffer(offers))">
                SUPPRESSED
              </button>
            </mat-menu>
            <div class="grid-col-span-2 offer-name" title="{{ getActiveOffer(offers).Name }}" fxLayout="row wrap"
              fxLayoutAlign="start start" fxLayoutGap="15px">
              <mat-checkbox *ngIf="!readOnlyMode" (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
                (change)="$event ? selection.toggle(offers) : null" [checked]="selection.isSelected(offers)">
              </mat-checkbox>
              <!-- <div class="ellipsis-3-line offer-name-detail">{{ getActiveOffer(offers).Name }}</div> -->
            </div>
            <div fxLayoutAlign="center center" class="offer-image">
              <!-- <img mat-card-md-image *ngIf="offers.FileName" src="{{viewModel.assetsUrl}}/{{offers.FileName}}" alt="offer image">
                      <img mat-card-md-image *ngIf="!offers.FileName" src="./assets/images/default-image.png" alt="offer image"> -->
              <ng-container *ngIf="offers.FileName">
                <img mat-card-md-image [src]="getImageSrc(offers)" alt="Offer image" />
              </ng-container>
              <img mat-card-md-image *ngIf="!offers.FileName" src="./assets/images/default-image.png"
                alt="Offer image" />
            </div>
            <div class="offer-content" fxLayout="column">
              <div *ngIf="offers.OfferVariants.length > 1" class="offer-bodycopy">
                <mat-form-field class="versions" appearance="outline">
                  <mat-select [formControlName]="i" (selectionChange)="getSelectedVersionDetail($event, offers)">
                    <mat-option *ngFor="let version of offers.OfferVariants" [value]="version">
                      {{version.OfferVariantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <span class="ellipsis-3-line">Version:
                  <span title="{{ offers.VersionNames }}">{{
                    offers.VersionNames
                    }}</span></span> -->
              </div>
              <div class="offer-headline">
                <span [innerHtml]="getActiveOffer(offers).PricingPreviewHtml"></span>
              </div>


            </div>
            <div class="comments">
                <div *ngIf="offers.CommentsCount" class="pl-18">
                  <div id={{offers.DomainId}} (click)="openDialog($event,offers)" class="comments-dialog">
                    <i class="fa fa-comment font-12" aria-hidden="true"></i>
                    <span class="comments-count">{{offers?.CommentsCount}}</span>
                  </div>
                </div>
                <div *ngIf="offers.CommentsCount==0" class="pl-18">
                  <span id={{offers.DomainId}} (click)="openDialog($event,offers)" class="comments-dialog">
                    <i class="fa fa-comment-o font-12" aria-hidden="true"></i>
                  </span>
                </div>
             
            </div>
              
            <div class="card-footer grid-col-span-2" fxLayout="row wrap" fxLayoutGap="15px">
              <span title="{{ getActiveOffer(offers).OfferTags }}" class="card-footer-text w-100"> 
                Tags : {{ getActiveOffer(offers).OfferTags }}</span>
              <span title="{{ getActiveOffer(offers).Versions }}" class="card-footer-text version-codes w-100"> 
                Versions : {{ getActiveOffer(offers).Versions }}</span>
              <div class="card-footer-text">
                <span class="footer-text">{{ offers.EventType }}<span *ngIf="offers.EventType == 'OFFERGROUP'">({{offers.OfferGroupOffersCount}})</span></span>
                <span class="footer-text display">Client Key:
                  <span title="{{ getActiveOffer(offers).ClientKey }}">{{
                    getActiveOffer(offers).ClientKey
                    }}</span></span>

              </div>
              <div fxLayOut="row" class="card-footer-btns">
                <button *ngIf="offers.OfferVariantsCount && offers.OfferVariantsCount > 1 && offers.EventType === 'OFFER'" mat-button type="button"
                  color="accent" [routerLink]="[getActiveOffer(offers).Id, 'childversions']">
                  Variants ({{offers.OfferVariantsCount}})</button>
                <button *ngIf="offers.OfferVariantsCount && offers.OfferVariantsCount > 1 && offers.EventType === 'PROMO'" mat-button type="button"
                  color="accent" [routerLink]="[getActiveOffer(offers).Id,'promo', 'childversions']">
                  Variants ({{offers.OfferVariantsCount}})</button>
                <button *ngIf="offers.EventType === 'OFFER'" class="offer-button" type="button" mat-button
                  color="accent" [routerLink]="[getActiveOffer(offers).Id, 'details']" [queryParams]="{ offer_filters:'true' }">
                  <span *ngIf="canEdit()">Edit</span>
                  <span *ngIf="!canEdit()">View Details</span>
                </button>
                <button *ngIf="offers.EventType === 'PROMO'" class="offer-button" type="button" mat-button
                  color="accent" [routerLink]="[getActiveOffer(offers).Id, 'promos', 'promodetails']" [queryParams]="{ offer_filters:'true' }">
                  <span *ngIf="canEdit()">Edit</span>
                  <span *ngIf="!canEdit()">View Details</span>
                </button>
                <button *ngIf="offers.EventType === 'OFFERGROUP'"class="offer-button" type="button" mat-button
                  color="accent" [routerLink]="[getActiveOffer(offers).Id, 'offergroups', 'offergrpdetails']" [queryParams]="{ offer_filters:'true' }">
                  <span *ngIf="canEdit()">Edit</span>
                  <span *ngIf="!canEdit()">View Details</span>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>

  <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100, 500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator>

  <table *ngIf="!viewModel.offers.length">
    <tr>
      <td class="no-offerss">
        <h2>No offerss Found</h2>
      </td>
    </tr>
  </table>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
