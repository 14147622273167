import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Offer, OfferDomain, OfferService, OfferTag, OfferTagService, Tag } from '../..';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('OfferGroupTagsComponent');

interface DialogData {
  event: string;
}

@Component({
  selector: 'app-offer-group-tags',
  templateUrl: './offer-group-tags.component.html',
  styleUrls: ['./offer-group-tags.component.scss']
})

export class OfferGroupTagsComponent<Tag extends OfferTag> implements OnInit {
  selectedVersion: any;
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.offerTagService.allOfferTags$,
    this.offerTagService.isLoading$,
    this.offerTagService.tagTotalRecords$,
    this.offerTagService.page$,
  ]).pipe(
    map(([versions, isLoading, totalRecords, page]) => {
      return { versions, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  tagsList: Tag[] = [];
  selection = new SelectionModel<Tag>(true, []);
  displayedColumns = ['select', 'OfferTagName'];
  constructor(private mediaObserver: MediaObserver, private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<OfferGroupTagsComponent<Tag>>,
    private offerTagService: OfferTagService<Tag>) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerTagService.allOfferTags$.subscribe((data) => {
      this.tagsList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['OfferTagName'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'OfferTagName'];
        }
        else {
          this.displayedColumns = ['select', 'OfferTagName'];
        }
      });
  }

  onSearch(event: any) {
    this.offerTagService.search(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.offerTagService.search('');
  }

  onSort(sortState: Sort): void {
    this.offerTagService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.offerTagService.page(pageEvent);
  }

  closeDialog() {
    this.dialogRef.close();
    this.offerTagService.search('');
  }

  

  saveData() {
    this.selectedVersion = this.selection.selected.map(x => x);
    this.dialogRef.close(this.selectedVersion);
    this.offerTagService.search('');
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tagsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tagsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }


}
