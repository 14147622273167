<form *ngIf="assets$ | async as AssetDetails; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            <h2 class="title">{{AssetDetails?.AssetName}}</h2>

            <div formGroupName="detail">
                <mat-accordion class="AssetDetails" multi>
                    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                        <mat-card appearance="outlined" fxFlex="15" fxFlexFill fxLayoutAlign="center" class="marginTop">
                            <!-- <img mat-card-md-image
                                *ngIf="AssetDetails?.Detail?.FileName && assetsUrl$ | async as assetsUrl; else productIcon"
                                src="{{assetsUrl}}/{{AssetDetails?.Detail?.FileName }}" alt="product image">
                            <ng-template #productIcon>
                                <mat-icon>image</mat-icon>
                            </ng-template> -->
                            <ng-container *ngIf="AssetDetails?.Detail?.FileName && assetsUrl$ | async as assetsUrl">
                                <img mat-card-md-image [src]="getImageSrc(assetsUrl, AssetDetails)" alt="Offer image">
                            </ng-container>
                            <img mat-card-md-image *ngIf="!AssetDetails.Detail.FileName"
                                src="./assets/images/default-image.png" alt="Offer image">
                        </mat-card>
                        <div fxLayout="row" fxFlex="auto" fxLayoutGap="30px" class="marginTop">
                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Asset Name</mat-label>
                                <input matInput type="text" formControlName="assetName" />
                                <mat-error *ngIf="formGroup.controls.detail.controls.assetName.hasError('required')">
                                    Asset Name is required
                                </mat-error>
                                <mat-error *ngIf="formGroup.controls.detail.controls.assetName.status=='INVALID'">
                                    {{formGroup.controls.detail.controls.assetName.errors!['maxlength'] && 'Maximum
                                    length
                                    can be
                                    '+formGroup.controls.detail.controls.assetName.errors!['maxlength']['requiredLength']+
                                    '. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>File Name</mat-label>
                                <input matInput readonly type="text" formControlName="fileName" />
                                <mat-error *ngIf="formGroup.controls.detail.controls.fileName.hasError('required')">
                                    File Name is required
                                </mat-error>
                                <!-- <mat-error *ngIf="formGroup.controls.detail.controls.fileName.status=='INVALID'">
                                    {{formGroup.controls.detail.controls.fileName.errors!['maxlength'] && 'Maximum length
                                    can be
                                    '+formGroup.controls.detail.controls.fileName.errors!['maxlength']['requiredLength']}}
                                    {{formGroup.controls.detail.controls.fileName.errors!['pattern'] && 'Offer Name may
                                    only contain alphanumeric and -#&*\'/characters'}}
                                </mat-error> -->
                            </mat-form-field>
                            <div fxFlex="33" class="previeBtnMarginTop">
                                <button mat-raised-button color="primary" (click)="openPreviewDialog()">Preview</button>
                            </div>
                        </div>          
                    </div>  



                    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" class="overlay-row">
                            <div fxFlex="15" fxFlexFill fxLayoutAlign="center" >
                                &nbsp;
                            </div>
                            <div *ngIf="AssetDetails.Type.indexOf('image') > -1" fxFlex="80" fxFlexFill fxLayoutAlign="left" >
                                <div fxFlex="50" fxFlexFill fxLayoutAlign="left" >
                                    <mat-form-field fxFlex="85" appearance="outline"
                                        >
                                        <mat-label>Web URL</mat-label>
                                        <input matInput type="text" formControlName="webUrl" />
                                        <mat-error *ngIf="formGroup.controls.detail.controls.webUrl.status=='INVALID'">
                                                {{formGroup.controls.detail.controls.webUrl.errors!['pattern'] && 'Invalid URL'}}
                                            </mat-error>
                                    </mat-form-field>
                                    <button mat-icon-button [disabled]="!formGroup.controls.detail.controls.webUrl.value || validateUrl(formGroup.controls.detail.controls.webUrl.value)" (click)="openUrl(formGroup.controls.detail.controls.webUrl.value)" >
                                        <mat-icon >launch</mat-icon>
                                    </button>
                                </div>
                                <div fxFlex="50" fxFlexFill fxLayoutAlign="left" >
                                    <mat-form-field fxFlex="85" appearance="outline">
                                        <mat-label>Print URL</mat-label>
                                        <input matInput type="text" formControlName="printUrl" />
                                        <mat-error *ngIf="formGroup.controls.detail.controls.printUrl.status=='INVALID'">
                                            {{formGroup.controls.detail.controls.printUrl.errors!['pattern'] && 'Invalid URL'}}
                                        </mat-error>
                                    </mat-form-field>
                                <button mat-icon-button [disabled]="!formGroup.controls.detail.controls.printUrl.value || validateUrl(formGroup.controls.detail.controls.printUrl.value)" (click)="openUrl(formGroup.controls.detail.controls.printUrl.value)" >
                                    <mat-icon >launch</mat-icon>
                                </button>
                          </div>
                          </div>
                        </div>     
                    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Asset Group</mat-label>
                            <mat-select formControlName="assetGroup">
                                <mat-option *ngFor="let group of assetGroups$" [value]="group.Id">
                                    {{group.AssetGroupName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Date Uploaded</mat-label>
                            <input matInput type="text" formControlName="dateAdded" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.dateAdded.status=='INVALID'">
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex>
                            <mat-label>Date Created</mat-label>
                            <input matInput [matDatepicker]="dateCreatedPicker" formControlName="dateCreated">
                            <mat-datepicker-toggle matSuffix [for]="dateCreatedPicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateCreatedPicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tags</mat-label>
                            <mat-chip-grid #chipList aria-label="Tags selection" [formControl]="tagCtrl">
                                <mat-chip-row *ngFor="let tag of OfferTagIds" (removed)="removeTag(tag)">
                                    {{tag.OfferTagName}}
                                    <button matChipRemove type="button">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                                <input placeholder="Type a Tag and Press Enter" [matChipInputFor]="chipList"
                                    [matAutocomplete]="auto" (matChipInputTokenEnd)="addTag($event)"
                                    [formControl]="tagCtrl">
                            </mat-chip-grid>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
                                <mat-option *ngFor="let tag of offerTags$ | async" [value]="tag">
                                    {{tag.OfferTagName}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="tagCtrl.status=='INVALID'">
                                {{tagCtrl.errors!['maxlength'] && 'Maximum length can be ' +
                                tagCtrl.errors!['maxlength']['requiredLength']}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Client Key</mat-label>
                            <input matInput type="text" formControlName="clientKey" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.clientKey.hasError('required')">
                                Client Key is required
                            </mat-error>
                            <mat-error *ngIf="formGroup.controls.detail.controls.clientKey.status=='INVALID'">
                                {{formGroup.controls.detail.controls.clientKey.errors!['maxlength'] && 'Maximum length
                                can be ' +
                                formGroup.controls.detail.controls.clientKey.errors!['maxlength']['requiredLength'] +
                                "."}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Description</mat-label>
                            <input matInput type="text" formControlName="description" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>Additional Specs</mat-label>
                            <input matInput type="text" formControlName="additionalSpecs" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Date Shot</mat-label>
                            <input matInput [matDatepicker]="dateShotPicker" formControlName="dateShot">
                            <mat-datepicker-toggle matSuffix [for]="dateShotPicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateShotPicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Original File Name</mat-label>
                            <input matInput type="text" formControlName="originalFileName" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.originalFileName.hasError('required')">
                                Original File Name is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>User Name Modified</mat-label>
                            <input matInput type="text" formControlName="userNameModified" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Date File Modified</mat-label>
                            <input matInput [matDatepicker]="dateFilePicker" formControlName="dateFileModified">
                            <mat-error *ngIf="formGroup.controls.detail.controls.dateFileModified.status=='INVALID'">
                                {{formGroup.controls.detail.controls.dateFileModified.errors!['matDatepickerMax']
                                && 'Date File Modified exceeded maximum year.'}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="dateFilePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #dateFilePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Color Channel</mat-label>
                            <input matInput type="text" formControlName="colorChannel" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.colorChannel.hasError('required')">
                                Color Channel is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>File Type</mat-label>
                            <input matInput type="text" formControlName="fileType" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.fileType.hasError('required')">
                                File Type is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Asset Type</mat-label>
                            <input matInput type="text" formControlName="assetType" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.assetType.hasError('required')">
                                Asset Type is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Image Source</mat-label>
                            <input matInput type="text" formControlName="imageSource" />
                        </mat-form-field>

                        <mat-checkbox class="check-box" (click)="$event.stopPropagation()"
                            formControlName="deleteStatus">
                            Delete Status
                        </mat-checkbox>

                        <mat-checkbox class="check-box" (click)="$event.stopPropagation()" formControlName="doNotUse">
                            Do Not Use
                        </mat-checkbox>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Expiry Date (Usage To/From?)</mat-label>
                            <input matInput [matDatepicker]="expiryDatePicker" formControlName="expiryDate">
                            <mat-error *ngIf="formGroup.controls.detail.controls.expiryDate.status=='INVALID'">
                                {{formGroup.controls.detail.controls.expiryDate.errors!['matDatepickerMax']
                                && 'Date File Modified exceeded maximum year.'}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="expiryDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #expiryDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Embargo Date</mat-label>
                            <input matInput [matDatepicker]="embargoDatePicker" formControlName="embargoDate">
                            <mat-error *ngIf="formGroup.controls.detail.controls.embargoDate.status=='INVALID'">
                                {{formGroup.controls.detail.controls.embargoDate.errors!['matDatepickerMax']
                                && 'Date File Modified exceeded maximum year.'}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="embargoDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #embargoDatePicker></mat-datepicker>
                        </mat-form-field>

                        <div fxFlex="50">
                            <div style="max-height: 275px; overflow: auto;"
                                *ngIf="additionalTexts && additionalTexts.controls && additionalTexts.controls.length > 0"
                                formArrayName="keywords">
                                <label>Keywords</label>
                                <ng-container *ngFor="let additionalText of additionalTexts.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <input matInput placeholder="Enter Text"
                                                formControlName="addtionalTextDetail">
                                            <mat-error *ngIf="additionalText.status=='INVALID'">
                                                {{additionalTexts.controls[i].get('addtionalTextDetail')?.errors?.['maxlength']
                                                && 'Maximum length can be '+
                                                additionalTexts.controls[i].get('addtionalTextDetail')?.errors?.['maxlength']['requiredLength']+'.'}}
                                                {{additionalTexts.controls[i].get('addtionalTextDetail')?.errors?.['pattern']
                                                && 'Comment may only contain alphanumeric and .,;-#&*\'/
                                                characters. '}}
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-icon style="margin: 15px;" class="delete-btn"
                                            (click)="deleteAddtionalText(i)">delete_forever</mat-icon>
                                    </div>
                                </ng-container>
                            </div>
                            <button type="button" style="margin: 10px;" mat-button (click)="addAdditionalText('')">
                                <mat-icon class="add-course-btn">add</mat-icon>
                                Add Keyword
                            </button>
                        </div>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Restrictions</mat-label>
                            <input matInput type="text" formControlName="restrictions" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Stock source information</mat-label>
                            <input matInput type="text" formControlName="stockSourceInformation" />
                        </mat-form-field>
                    </div>



                </mat-accordion>
            </div>
        </mat-card-content>

        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="assetId != '0'?'space-between':'end'" class="mat-card-actions-details">
                <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="assetId != '0'">
                    Delete
                </button>
                <div>
                    <button mat-stroked-button type="button" (click)="cancel()">
                        Cancel
                    </button>
                    <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid"
                        color="accent">
                        Save
                    </button>
                </div>
            </div>
        </mat-card-actions>

    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>