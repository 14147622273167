import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Subscription, combineLatest, forkJoin, map } from 'rxjs';
import { OrganizationService } from 'src/app/@shared';
import { AuthUserService } from '../../services/authuser.service';
import { User, UserService } from '../..';

@Component({
  selector: 'app-add-user-organization-details',
  templateUrl: './add-user-organization-details.component.html',
  styleUrls: ['./add-user-organization-details.component.scss'],
})
export class AddUserOrganizationDetailsComponent<T extends User>
  implements OnInit, OnDestroy
{
  form: FormGroup | undefined;
  selectAllEvents = false;
  selectAllChannels = false;

  allComplete: boolean = false;
  allChannelsComplete: boolean = false;
  organizationsList: any[] = [];
  divisionsList: any[] | undefined;
  userRoles: any[] = [];
  userStatus: any[] = [
    { value: true, viewValue: 'Active' },
    { value: false, viewValue: 'InActive' },
  ];

  eventTypes: any = [];

  subscribedChannels: any;
  subscribedDomains: any;
  userRolesDomainsAndChannelsData: any;
  productCategoriesList: any[] = [];

  /** Stores all this component's subscriptions at one place */
  subscription$: Subscription = new Subscription();

  viewModel$ = combineLatest([this.organizationService.organizations$]).pipe(
    map(([organizations]) => {
      return {
        organizations,
      };
    })
  );

  constructor(
    private rootFormGroup: FormGroupDirective,
    private umApi: UserService<T>,
    private organizationService: OrganizationService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    const currentValue = this.form.value;
    currentValue.organization = currentValue.organization ? currentValue.organization : this.organizationService.organization;
    if (currentValue.organization) {
      this.getSelectedOrganizationDetail({ value: currentValue.organization });
      this.form?.controls['organization'].patchValue(currentValue.organization);
      this.form?.controls['organization'].disable();
    }
  }

  getSelectedOrganizationDetail(event: any) {
    const orgApi = event.value.apiPath;
    const currentValue = this.form?.value;
    this.umApi.getDivisions(orgApi).subscribe((res) => {
      this.divisionsList = res.value;
      this.cdRef.detectChanges();
    });
    this.umApi.getUserRoles(orgApi).subscribe((res) => {
      if (res && res.length > 0) {
        const currentOrgRoles = res.filter(
          (x: any) =>
            x.Description &&
            x.Description.toLowerCase().includes(event.value.name.toLowerCase())
        );
        this.userRoles = currentOrgRoles;
      }
    });
    this.umApi.getChannels(orgApi).subscribe((res) => {
      this.subscribedChannels = res.value;
      if (
        this.subscribedChannels &&
        this.subscribedChannels.length > 0 &&
        currentValue.organization &&
        currentValue.channels &&
        currentValue.channels.length > 0
      ) {
        this.subscribedChannels.map(
          (i: any) => (i.selected = currentValue.channels.includes(i.Id))
        );
        this.form?.controls['channels'].patchValue(this.subscribedChannels);
      }
    });
    this.umApi.getEventTypes(orgApi).subscribe((res) => {
      this.eventTypes = res.value;
      if (
        this.eventTypes &&
        this.eventTypes.length > 0 &&
        currentValue.organization &&
        currentValue.event_types &&
        currentValue.event_types.length > 0
      ) {
        this.eventTypes.map(
          (i: any) => (i.selected = currentValue.event_types.includes(i.Id))
        );
        this.form?.controls['event_types'].patchValue(this.eventTypes);
      }
    });

    // this.umApi.getProductCategoriesList(orgApi).subscribe((res) => {
    //   this.productCategoriesList = res.value;
    //   this.cdRef.detectChanges();
    // });

    this.umApi.getParentLevelCategories().subscribe((res: any) => {
      this.productCategoriesList = res.value.map((r: any) => r.Detail);
      this.cdRef.detectChanges();
    });
  }

  eventTypeSelected(event: any, eventType: any) {
    eventType.selected = event.checked;
    this.form?.controls['event_types'].patchValue(this.eventTypes);
  }

  channelSelected(event: any, eventType: any) {
    eventType.selected = event.checked;
    this.form?.controls['channels'].patchValue(this.subscribedChannels);
  }

  compareObjects(o1: any, o2: any): boolean {
    return o2?.Name?.includes(o1?.viewValue) && o1?.value == o2?.Id;
  }

  setAllChannels(complete: boolean) {
    if (this.subscribedChannels == null) {
      return;
    }
    this.subscribedChannels.forEach((t: any) => (t.selected = complete));
    this.form?.controls['channels'].patchValue(this.subscribedChannels);
  }
  setAllEvents(completed: boolean) {
    this.allComplete = completed;
    if (this.eventTypes == null) {
      return;
    }
    this.eventTypes.forEach((t: any) => (t.selected = completed));
    this.form?.controls['event_types'].patchValue(this.eventTypes);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
