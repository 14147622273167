import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewChecked,
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { AddWorkflowUsersComponent } from './add-workflow-users/add-workflow-users.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EventWorkflowService } from '../../../services/event-workflow.service';
import {
  ConfirmDialogComponent,
  ErrorDialogComponent,
  NotificationService,
  OrganizationService,
} from 'src/app/@shared';
import { MatStepper } from '@angular/material/stepper';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { AuthorizationService } from 'src/app/@shared/services/authorization.service';

interface WorkflowUser {
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-edit-workflow',
  templateUrl: './edit-workflow.component.html',
  styleUrls: ['./edit-workflow.component.scss'],
})
export class EditWorkflowComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  editWorkflowForm: FormGroup | undefined;
  template_header = 'Template Header';
  panelOpenState = false;
  workflowStatus = '';

  startingTriggerDropdownData = [
    { label: 'When workflow is applied to an Event', value: 0 },
    { label: 'When workflow2 is applied to an Event', value: 1 },
    { label: 'When workflow3 is applied to an Event', value: 2 },
    { label: 'When workflow4 is applied to an Event', value: 3 },
  ];

  endingTriggerDropdownData = [
    {
      label: `When all Approvers have marked all offers as "Approved"`,
      value: 0,
    },
    {
      label: `When all Approvers have marked all offers as "Approved2"`,
      value: 1,
    },
    {
      label: `When all Approvers have marked all offers as "Approved3"`,
      value: 2,
    },
    {
      label: `When all Approvers have marked all offers as "Approved4"`,
      value: 3,
    },
  ];

  templateIdValue: string | undefined = undefined;
  public selectedUsersIndex: any;
  duplicateStageFound: boolean[] = [];
  onlyOneStageError: boolean = false;
  newUsersAddedToCurrentWorkflow: any = [];
  currentUserPresentInActiveStage: boolean = false;
  workflowInProgress: boolean = false;

  @Input() set templateId(val: any) {
    this.templateIdValue = val;
    this.getWorkflow(this.templateIdValue!);
  }

  @Output() onClose = new EventEmitter();

  dataSource: MatTableDataSource<any> | undefined;
  selection = new SelectionModel<any>(true, []);
  startTriggerRefData: any;
  endTriggerRefData: any;
  workflowActiveGroupsRefData: any;
  allWorkflowGroups: any;
  subscription$ = new Subscription();
  showActions: boolean[] = [];
  isNewWorkflow = false;
  gotWorkflowDetails = false;
  ewId: any = '';
  ewData: any;
  public expandedPanels: any = [];
  public usersDeleted = false;
  public emailAlerts = [
    'All Activity',
    'Replies to my comments',
    'Decisions',
    'Final Decisions',
    'Disabled',
  ];

  public taskItems = [
    'All Activity',
    'Replies to my comments',
    'Decisions',
    'Final Decisions',
    'Disabled',
  ];

  // public stageTasks = ['User Approval', 'Document Upload', 'ETL Process'];
  public stageTasks = ['User Approval'];

  rolesRefData = [
    { label: 'Reviewer', value: 0 },
    { label: 'Editor', value: 1 },
    { label: 'Approver', value: 2 },
  ];

  needsSaving = false;
  emailAlertsRefData = [
    { label: 'All Activity', value: 0 },
    { label: 'Work Assigned', value: 1 },
    { label: 'Disabled', value: 2 },
  ];
  usersOfActiveStage = [];

  @ViewChild('stepper') stepper!: MatStepper;
  loggedInUsersRole: any;
  currentUserEmail = '';

  constructor(
    private ewService: EventWorkflowService,
    public auth: AuthService,
    public dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    public legacyDialogRef: MatDialog,
    public notificationService: NotificationService,
    private cdref: ChangeDetectorRef,
    private orgService: OrganizationService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.ewService.getWorkflowPermissionsForSelectedEvent(
      this.ewService.selectedEventId
    );
    setTimeout(() => {
      this.getControlsRefData();
    }, 100);
    this.createForm();
    if (this.router.url.includes('new-workflow')) {
      this.isNewWorkflow = true;
    }
    this.auth.getUser().subscribe((val: any) => {
      this.currentUserEmail = val?.email;
    });
  }

  ngAfterViewInit() {
    this.loggedInUsersRole = this.ewService.userRoleInCurrentWorkflow.value;
    this.subscription$.add(
      this.ewService.userRoleInCurrentWorkflow.subscribe(
        (r) => (this.loggedInUsersRole = r)
      )
    );
    this.openActivePanel();
  }

  ngAfterViewChecked() {
    this.autoCheckStagesForDuplicates();
    this.cdref.detectChanges();
  }

  openActivePanel() {
    const openPanelInterval = setInterval(() => {
      if (
        (this.editWorkflowForm?.get('workflow_stages') as FormArray)?.value
          .length > 0
      ) {
        const activeStageIndex = (
          this.editWorkflowForm?.get('workflow_stages') as FormArray
        )?.value?.findIndex((s: any) => {
          return s.stage_status == 'active';
        });
        this.onPanelOpen(activeStageIndex > -1 ? activeStageIndex : 0);
        clearInterval(openPanelInterval);
      }
    }, 1000);

    setTimeout(() => clearInterval(openPanelInterval), 5000);
  }

  getSelectedUsers() {}

  getControlsRefData() {
    this.subscription$.add(
      this.ewService
        .getRefDataForEditWorkflowControls()
        .subscribe((data: any) => {
          this.emailAlerts = data?.emailAlerts?.map((e: any) => e.Name);
          this.taskItems = data?.tasks?.map((t: any) => t.Name);
          this.startTriggerRefData = data.startTriggers;
          this.endTriggerRefData = data.endTriggers;
          this.allWorkflowGroups = data.workflowGroups;
          this.workflowActiveGroupsRefData = data.workflowGroups.filter(
            (w: any) => w.IsActive == true
          );
        })
    );
  }

  getWorkflow(templateId: string) {
    this.ewService.loadingBehaviorSubject.next(true);
    this.createForm();
    this.subscription$.add(
      this.ewService
        .getWorkflowDetailsForFeature(
          templateId,
          this.orgService.silentAuthToken
        )
        .subscribe((data: any) => {
          for (let i = 0; i < data?.workflow_stages?.length; i++) {
            let workflowS = this.workflowStage;
            for (let u of data.workflow_stages[i]?.users) {
              (workflowS.get('users') as FormArray).push(
                this.workflowUserFormControl
              );
            }
            // Setting stage status manually in UI
            const changedData = this.setStageStatusBasedOnOrderAndSkipped(
              data.workflow_stages[i],
              data,
              i
            );
            data = changedData.data;
            data.workflow_stages[i] = changedData.workflowS;

            workflowS.patchValue(data.workflow_stages[i]);

            this.availableWorkflowStages.push(workflowS);
          }
          this.editWorkflowForm?.patchValue(data);
          this.ewId = data.id;
          this.ewData = data;
          if (this.ewData.workflow_status == 'in_progress') {
            this.workflowStatus = 'In Progress';
          } else if (this.ewData.workflow_status == 'paused') {
            this.workflowStatus = 'Paused';
          } else if (this.ewData.workflow_status == 'completed') {
            this.workflowStatus = 'Completed';
          } else {
            this.workflowStatus = '';
          }
          this.workflowInProgress =
            this.workflowStatus?.trim()?.toLowerCase() == 'in progress';
          // const unskippedStages = data.workflow_stages.filter(
          //   (s: any) => s.stage_status != 'skipped'
          // );

          // if (unskippedStages.length == 0) {
          //   this.onlyOneStageError = true;
          // }

          // if (this.onlyOneStageError == true) {
          //   this.availableWorkflowStages.push(this.workflowStage);
          // }

          this.gotWorkflowDetails = true;
          this.ewService.loadingBehaviorSubject.next(false);

          for (let i = 0; i < this.availableWorkflowStages.length; i++) {
            if (
              this.availableWorkflowStages[i].value.stage_status == 'skipped'
            ) {
              this.availableWorkflowStages[i].setValidators(null);
              this.availableWorkflowStages[i].updateValueAndValidity();
            }
          }

          setTimeout(() => {
            if (
              this.editWorkflowForm?.value.workflow_status != 'completed' &&
              this.checkIfWorkflowComplete(true) == true
            ) {
              this.editWorkflowForm?.patchValue({
                ...this.editWorkflowForm.value,
                workflow_status: 'completed',
              });

              this.onSave(true);
            }
          }, 100);
          this.getEmailIdsOfActiveStage();
        })
    );
  }

  getEmailIdsOfActiveStage() {
    const stages = this.editWorkflowForm?.value['workflow_stages'];
    const activeStageIndex = stages?.findIndex(
      (s: any) => s?.stage_status == 'active'
    );
    if (activeStageIndex > -1) {
      this.usersOfActiveStage = stages[activeStageIndex]?.users.filter(
        (u: any) => u?.email_alerts?.trim()?.toLowerCase() != 'disabled'
      );
      this.ewService.setUsersOfActiveStage(this.usersOfActiveStage);
    } else {
      this.usersOfActiveStage = [];
      this.ewService.setUsersOfActiveStage(this.usersOfActiveStage);
    }
    const cui = this.usersOfActiveStage.findIndex(
      (u: any) =>
        u?.email?.toLowerCase()?.trim() ==
        this.currentUserEmail?.toLowerCase()?.trim()
    );
    this.currentUserPresentInActiveStage = cui > -1;
  }

  setStageStatusBasedOnOrderAndSkipped(workflowS: any, data: any, i: number) {
    // if (
    //   i == 0 &&
    //   (!data.workflow_stages[i].stage_status ||
    //     data.workflow_stages[i].stage_status.trim().length == 0)
    // ) {
    //   workflowS.patchValue({ stage_status: 'active' });
    // }
    // if (
    //   i > 0 &&
    //   data.workflow_stages[i - 1].stage_status != 'active' &&
    //   data.workflow_stages[i - 1].stage_status != 'not_started' &&
    //   data.workflow_stages[i - 1].stage_status != 'skipped'
    // ) {
    //   workflowS.patchValue({ stage_status: 'active' });
    // }
    // if (
    //   i > 0 &&
    //   (data.workflow_stages[i - 1].stage_status == 'active' ||
    //     data.workflow_stages[i - 1].stage_status == 'not_started' ||
    //     data.workflow_stages[i - 1].stage_status == 'skipped' ||
    //     data.workflow_stages[i - 1].stage_status == 'skipped')
    // ) {
    //   workflowS.patchValue({ stage_status: 'not_started' });
    // }

    if (
      !workflowS?.stage_status ||
      workflowS?.stage_status == null ||
      workflowS?.stage_status?.trim().length == 0
    ) {
      if (
        i > 0 &&
        data.workflow_stages[i - 1].stage_status &&
        data.workflow_stages[i - 1].stage_status.trim().length > 0 &&
        data.workflow_stages[i - 1].stage_status != 'not_started' &&
        data.workflow_stages[i - 1].stage_status != 'active'
      ) {
        workflowS.stage_status = 'active';
      } else if (i == 0) {
        workflowS.stage_status = 'active';
      } else {
        workflowS.stage_status = 'not_started';
      }
    } else if (
      (workflowS?.stage_status == 'completed' ||
        workflowS?.stage_status == 'skipped') &&
      (!data?.workflow_stages[i + 1]?.stage_status ||
        data?.workflow_stages[i + 1]?.stage_status == null ||
        data?.workflow_stages[i + 1]?.stage_status == 'not_started')
    ) {
      if (data.workflow_stages[i + 1]) {
        data.workflow_stages[i + 1].stage_status = 'active';
      }
    }
    return { workflowS, data };
  }

  createForm() {
    this.editWorkflowForm = new FormGroup({
      template_info: new FormGroup({
        template_name: new FormControl(undefined, Validators.required),
        template_group: new FormControl({ value: undefined, disabled: true }),
        template_category: new FormControl(undefined, Validators.required),
      }),
      workflow_config: new FormControl(['ATL', 'OnSite']),
      workflow_stages: new FormArray([]),
      workflow_status: new FormControl('not_started'),
    });
  }

  templateInfoInvalid() {
    return this.editWorkflowForm?.get('template_info')?.invalid;
  }

  get workflowStage() {
    return new FormGroup({
      stage_name: new FormControl('Stage Name', Validators.required),
      stage_description: new FormControl(undefined, Validators.required),
      stage_task: new FormControl(undefined, Validators.required),
      stage_id: new FormControl(undefined),
      stage_status: new FormControl(''),
      sort_order: new FormControl(undefined),
      users: new FormArray([]), // Add workflow users here
    });
  }

  get workflowUserFormControl() {
    return new FormGroup({
      first_name: new FormControl(),
      last_name: new FormControl(),
      role: new FormControl(),
      id: new FormControl(),
      email: new FormControl(),
      email_alerts: new FormControl(),
    });
  }

  public get availableWorkflowStages() {
    return (this.editWorkflowForm?.get('workflow_stages') as FormArray)
      ?.controls;
  }

  public getAvailableUsersInStage(i: number): FormArray {
    return (
      this.editWorkflowForm?.get('workflow_stages') as FormArray
    )?.controls[i]?.get('users') as FormArray;
  }

  closeEditWorkflow() {
    if (this.needsSaving == true) {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'Cannot Close',
          hideCanel: true,
          confirmText: 'Ok',
          message: 'Workflow needs to be saved',
        },
      });
      dialogRef.afterClosed().subscribe(() => {});
      return;
    } else {
      if (this.router.url.includes('new-workflow')) {
        this.router.navigate([`../`], { relativeTo: this.route });
      } else {
        this.onClose.emit();
      }
    }
  }

  cancelEditWorkflow() {
    if (
      (this.editWorkflowForm?.touched && this.editWorkflowForm?.dirty) ||
      this.anyStageTouchedAndDirty()
    ) {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'Close without saving?',
          cancelText: 'Close',
          confirmText: 'Continue Editing',
          message: `${this.ewData.template_info.template_name} has unsaved changes.`,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == false) {
          this.closeEditWorkflow();
        }
      });
    } else {
      this.closeEditWorkflow();
    }
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource!.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource!.data.length;
    return numSelected === numRows;
  }

  anyStageInvalid() {
    const i = this.availableWorkflowStages.findIndex(
      (s: any) => s.invalid == true
    );

    return i > -1;
  }

  anyStageTouchedAndDirty() {
    const i = this.availableWorkflowStages.findIndex(
      (s: any) => s.touched == true && s.dirty == true
    );

    return i > -1;
  }

  anyStageWithNoUsers() {
    const i = this.availableWorkflowStages.findIndex((s: any) => {
      return s.value.users.length == 0;
    });
    return i > -1;
  }

  onSave(
    forceSave = false,
    stageCompletion = false,
    stageCompletedNotificationMsg: string = '',
    index = 0
  ) {
    this.getEmailIdsOfActiveStage();
    const payload = this.getPayloadFromEditWorkflowForm();
    this.ewService
      .createUpdateWorkflow(
        payload,
        this.ewId,
        this.ewService.selectedEventId,
        this.ewService.eventName,
        stageCompletion == true ? stageCompletedNotificationMsg : undefined
      )
      ?.subscribe((res: any) => {
        this.notifyNewlyAddedUsersInWorkflow(payload);
        if (stageCompletion == true) {
          this.triggerEmailForActiveUsersInNextStage(index);
        }
        if (!forceSave) {
          this.needsSaving = false;
          this.onlyOneStageError = false;
          this.closeEditWorkflow();
          this.notificationService.showSuccess(`${payload.Name} saved.`);
        }
        if (forceSave == true) {
          this.getWorkflow(this.templateIdValue!);
        }
        setTimeout(() => this.checkIfWorkflowComplete(), 0);

        // Get updated permissions
        this.ewService.getWorkflowPermissionsForSelectedEvent(
          this.ewService.getSelectedEventId()
        );
      });
  }

  SaveandActivateWorkflow() {
    this.getEmailIdsOfActiveStage();
    const payload = this.getPayloadFromEditWorkflowForm();
    this.ewService
      .createUpdateWorkflow(
        payload,
        this.ewId,
        this.ewService.selectedEventId,
        this.ewService.eventName
      )
      ?.subscribe((res: any) => {
        this.notifyNewlyAddedUsersInWorkflow(payload);
        this.needsSaving = false;
        this.onlyOneStageError = false;
        this.closeEditWorkflow();
        this.notificationService.showSuccess(`${payload.Name} saved.`);
        setTimeout(() => this.checkIfWorkflowComplete(), 0);

        // Get updated permissions
        this.ewService.getWorkflowPermissionsForSelectedEvent(
          this.ewService.getSelectedEventId()
        );
      });
  }

  notifyNewlyAddedUsersInWorkflow(payload: any) {
    if (payload && payload.WorkflowStatus !== 'paused') {
      const tasks = payload.Tasks;
      if (tasks && tasks.length > 0) {
        const activeTask = tasks.find((x: any) => x.StageStatus === 'active');
        if (
          activeTask &&
          activeTask.Assignments &&
          activeTask.Assignments.length > 0
        ) {
          let allEmails = activeTask.Assignments.map(
            (i: any) => i.Assignee.Email
          );
          if (
            this.ewData &&
            this.ewData.WorkflowStatus &&
            this.ewData.Tasks &&
            this.ewData.Tasks.length > 0
          ) {
            const prevActiveTask = this.ewData.Tasks.find(
              (x: any) => x.Name === activeTask.Name
            );
            if (
              prevActiveTask &&
              prevActiveTask.Users &&
              prevActiveTask.Users.length > 0
            ) {
              const prevTaskUsers = prevActiveTask.Users.map(
                (user: any) => user.Email
              );
              allEmails = allEmails.filter(
                (email: string) => !prevTaskUsers.includes(email)
              );
            }
          }
          if (allEmails && allEmails.length > 0) {
            // const msg = `You have been added to Stage <b>${activeTask.Name}</b> of Workflow <b>${payload.Name}</b> for Event <b>${this.ewService.eventName}</b>.`;
            const url =
              window.location.href.substring(
                0,
                window.location.href.lastIndexOf('/')
              ) + '/workflow';
            const msg = this.ewService.getEmailBody(
              this.ewService.eventName,
              payload.Name,
              activeTask.Name,
              url
            );
            const inappmsg = this.ewService.getInappBody(
              this.ewService.eventName,
              payload.Name,
              activeTask.Name,
              url
            );
            this.ewService.triggerCustomKnockNotification(
              allEmails,
              msg,
              '$users',
              'pr1-notification',
              inappmsg
            );
          }
        }
      }
    }
  }

  triggerEmailForActiveUsersInNextStage(i: number) {
    if (
      i != undefined &&
      i > -1 &&
      this.availableWorkflowStages.length > i + 1
    ) {
      const stageToMarkActive = this.availableWorkflowStages[i + 1].value;
      const urlWithDomainAndEventId = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/')
      );
      // const emailTemplateString = `<html><body>Stage <b>${
      //   this.availableWorkflowStages[i + 1].value.stage_name
      // }</b> of Workflow <b>${
      //   this.ewData.TemplateName
      // }</b>,  is now Active.<br /> Access the Event here:<br />  ${urlWithDomainAndEventId}/offers</body></html>`;

      const emailTemplateString = this.ewService.getEmailBody(
        this.ewService.eventName,
        this.ewData.TemplateName,
        this.availableWorkflowStages[i + 1].value.stage_name,
        `${urlWithDomainAndEventId}/offers`
      );

      const inappTemplateString = this.ewService.getInappBody(
        this.ewService.eventName,
        this.ewData.TemplateName,
        this.availableWorkflowStages[i + 1].value.stage_name,
        `${urlWithDomainAndEventId}/offers`
      );

      this.ewService.triggerCustomKnockNotification(
        stageToMarkActive.users?.map((u: any) =>
          (u.email as string)?.toLowerCase()?.trim()
        ),
        emailTemplateString,
        '$users',
        'pr1-notification',
        inappTemplateString
      );
      this.onPanelOpen(i + 1);
    }
  }

  changeWorkflowStatus() {
    if (this.ewData.workflow_status == 'in_progress') {
      this.editWorkflowForm?.patchValue({ workflow_status: 'paused' });
    } else {
      this.editWorkflowForm?.patchValue({ workflow_status: 'in_progress' });
    }
    this.SaveandActivateWorkflow();
  }

  getPayloadFromEditWorkflowForm() {
    this.checkIfWorkflowComplete();
    this.setSortOrderForEveryStage();
    const f = this.editWorkflowForm!.value;

    const payload = {
      WorkflowCategory: f.template_info.template_category,
      WorkflowGroup: f.template_info.template_group
        ? f.template_info.template_group
        : this.editWorkflowForm?.get('template_info')?.get('template_group')
            ?.value,
      WorkflowStatus: f.workflow_status,
      Id: this.ewId,
      Name: f.template_info.template_name,
      Description: '',
      CreatedBy: this.ewData?.UserId
        ? this.ewData?.UserId
        : f.template_info.template_owner,
      Tasks: this.availableWorkflowStages?.map((fg: any) => {
        const s = fg.value;
        let users = s.users?.map((u: any) => ({
          Assignee: {
            Id: u.id,
            FirstName: u.first_name,
            LastName: u.last_name,
            Email: u.email,
            EmailAlert: u.email_alerts,
            // UserTask: u.task,
          },
          Status: 0,
          Role: this.getRoleValueFromLabel(u.role),
        }));

        // users.push({
        //   Assignee: {
        //     IsOptional: false,
        //     Status: 0,
        //     Role: 2,
        //     FirstName: 'Admin',
        //     LastName: 'PureRed',
        //     Email: 'Admin@Purered.Net',
        //     EmailAlert: null,
        //     UserTask: '',
        //     Id: 'd08849bb-d857-4aca-87b6-8ffdcb233a53',
        //   },
        // });

        return {
          Name: s.stage_name,
          Id: s.id ? s.id : '',
          Description: s.stage_description,
          IsComplete: true,
          StageTask: s.stage_task,
          StageStatus: s.stage_status,
          SortOrder: s.sort_order,
          Assignments: users,
        };
      }),
    };

    return payload;
  }

  setSortOrderForEveryStage() {
    for (let i = 0; i < this.availableWorkflowStages.length; i++) {
      this.availableWorkflowStages[i].patchValue({ sort_order: i + 1 });
    }
  }

  getRoleValueFromLabel(label: string) {
    const i = this.rolesRefData.findIndex((r) => r.label == label);
    if (i > -1) {
      return this.rolesRefData[i].value;
    }
    return 0;
  }

  openAddUsersToWorkflow(stageIndex: number) {
    const dialogRef = this.dialog.open(AddWorkflowUsersComponent, {
      data: this.availableWorkflowStages[stageIndex].value?.users?.map(
        (u: any) => u.id
      ),
    });

    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result = result?.map((r: any) => ({
            first_name: r.FirstName,
            last_name: r.LastName,
            id: r.Id,
            email: r.Email,
            stage_name:
              this.availableWorkflowStages[stageIndex]?.value?.stage_name,
            workflow_name: this.ewData?.TemplateName,
            stage_active:
              this.availableWorkflowStages[stageIndex]?.value?.stage_status ==
              'active',
          }));

          for (let j = 0; j < result?.length; j++) {
            const existingUsers = this.getAvailableUsersInStage(stageIndex)
              .value as any[];
            const euIndex = existingUsers.findIndex(
              (e) =>
                (e.id as string)?.trim() == (result[j]?.id as string)?.trim()
            );

            if (euIndex == -1) {
              this.newUsersAddedToCurrentWorkflow.push(result[j]);
              const newUser = this.workflowUserFormControl;
              newUser.patchValue(result[j]);
              this.getAvailableUsersInStage(stageIndex).push(newUser);
            }
          }
        }
      })
    );
  }

  onSelectUsers(e: any, i: number) {
    this.showActions[i] = e;
  }

  addStage() {
    // if (this.onlyOneStageError == true) {
    //   this.needsSaving = true;
    // }
    this.availableWorkflowStages?.push(this.workflowStage);
    this.onPanelOpen(this.availableWorkflowStages.length - 1);
  }

  removeStage(i: number) {
    let onlyOneStageNotSkipped = false;
    const l = this.availableWorkflowStages.length;
    const skippedStages = this.availableWorkflowStages.filter((s) => {
      return s?.value?.stage_status == 'skipped';
    });
    onlyOneStageNotSkipped = l - skippedStages.length == 1;

    if (this.availableWorkflowStages.length == 1 || onlyOneStageNotSkipped) {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          message:
            'Cannot remove Stage. Need atleast one un-skipped stage in a template.',
          confirmText: 'Ok',
          hideCanel: true,
          title: 'Not allowed',
        },
      });
    } else {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          message: 'Are you sure you want to delete this stage?',
          confirmText: 'Yes',
          cancelText: 'No',
        },
      });
      this.subscription$.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (result == true) {
            this.availableWorkflowStages.splice(i, 1);
          }
        })
      );
    }
  }

  onPanelOpen(panelIndex: number, e?: any) {
    for (let i = 0; i < this.expandedPanels.length; i++) {
      this.expandedPanels[i] = false;
    }
    this.expandedPanels[panelIndex] = true;
    if (this.stepper && this.stepper.selectedIndex) {
      setTimeout(() => {
        this.stepper.selectedIndex = panelIndex;
      }, 0);
    }
  }

  onSelectionChange(e: any) {
    this.onPanelOpen(e.selectedIndex);
  }

  onStageTaskChange(e: any, i: number) {
    (this.availableWorkflowStages[i] as FormGroup)
      .get('stage_task')
      ?.patchValue(e.value);
  }

  selectUsers(users: any) {
    this.selectedUsersIndex = users;
  }

  deleteSelectedUsers(stageIndex: number) {
    const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete these users?',
        confirmText: 'Yes',
        cancelText: 'No',
      },
    });
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          for (let i = 0; i < this.selectedUsersIndex.length; i++) {
            // setTimeout(() => {
            const alreadyAddedUsers = this.availableWorkflowStages[
              stageIndex
            ]?.get('users') as FormArray;

            const indexToRemove = alreadyAddedUsers.value.findIndex(
              (u: any) => {
                return u.id == this.selectedUsersIndex[i];
              }
            );

            alreadyAddedUsers.removeAt(indexToRemove);
            // }, 100);
          }

          // setTimeout(
          //   () => this.resetUserSelection(stageIndex),
          //   100 * this.selectedUsersIndex.length
          // );
          this.resetUserSelection(stageIndex);
        }
      })
    );
  }

  emailAlertsMultiSet(e: string, stageIndex: number) {
    const currentStageUsers: FormArray = this.availableWorkflowStages[
      stageIndex
    ]?.get('users') as FormArray;

    for (let i = 0; i < this.selectedUsersIndex.length; i++) {
      const sui = currentStageUsers.value.findIndex(
        (c: any) => c.id == this.selectedUsersIndex[i]
      );
      setTimeout(() => {
        currentStageUsers.controls[sui]?.patchValue({ email_alerts: e });
      }, 200);
    }

    this.resetUserSelection(stageIndex);
  }

  // taskMultiSet(t: string, stageIndex: number) {
  //   const currentStageUsers: FormArray = this.availableWorkflowStages[
  //     stageIndex
  //   ]?.get('users') as FormArray;

  //   for (let i = 0; i < this.selectedUsersIndex.length; i++) {
  //     const sui = currentStageUsers.value.findIndex(
  //       (c: any) => c.id == this.selectedUsersIndex[i]
  //     );
  //     setTimeout(() => {
  //       currentStageUsers.controls[sui]?.patchValue({ task: t });
  //     }, 200);
  //   }

  //   this.resetUserSelection(stageIndex);
  // }

  rolesMultiSet(t: any, stageIndex: number) {
    const currentStageUsers: FormArray = this.availableWorkflowStages[
      stageIndex
    ]?.get('users') as FormArray;

    for (let i = 0; i < this.selectedUsersIndex.length; i++) {
      const sui = currentStageUsers.value.findIndex(
        (c: any) => c.id == this.selectedUsersIndex[i]
      );
      setTimeout(() => {
        currentStageUsers.controls[sui]?.patchValue({ role: t });
      }, 200);
    }

    this.resetUserSelection(stageIndex);
  }

  resetUserSelection(stageIndex: number) {
    this.usersDeleted = true;
    this.selectedUsersIndex = [];
    this.showActions[stageIndex] = false;
    setTimeout(() => {
      this.usersDeleted = false;
    }, 100);
  }

  public get availableStageNames() {
    return this.availableWorkflowStages.map((fg: any) => {
      return fg?.value?.stage_name;
    });
    // return (
    //   this.editWorkflowForm?.get('workflow_stages') as FormArray
    // ).value.map((ws: any) => ws.stage_name);
  }

  public autoCheckStagesForDuplicates() {
    for (let i = 0; i < this.availableStageNames.length; i++) {
      this.checkIfStageExists(this.availableStageNames[i], i);
    }
  }

  public checkIfStageExists(stageNameControl: any, stageIndex: number) {
    const val: string =
      typeof stageNameControl == 'string'
        ? stageNameControl
        : stageNameControl.target.value;
    const stageExistsIndex = this.availableStageNames.findIndex(
      (sn: string) => sn?.toLowerCase()?.trim() == val?.toLowerCase()?.trim()
    );

    const duplicateIndexes = this.availableStageNames.reduce(
      (r, sn, i) =>
        r.concat(
          sn?.toLowerCase()?.trim() == val?.toLowerCase()?.trim() ? i : []
        ),
      []
    );

    this.duplicateStageFound[stageIndex] = duplicateIndexes.length > 1;
  }

  public get duplicateInAnyStage() {
    return this.hasDuplicates(
      this.availableStageNames.map((s: string) => s.toLowerCase().trim())
    );
  }

  public hasDuplicates(array: any[]) {
    const x = new Set(array).size !== array.length;
    return x;
  }

  createNewWorkflowGroup(r: any) {
    const payload = {
      Id: '',
      Name: r.group_name,
      IsActive: r.status === 'Active',
      Description: r.description,
      Channel: r.channel,
      CreatedBy: this.ewData?.UserId,
      CreatedDate: new Date().toISOString(),
    };
    this.subscription$.add(
      this.ewService.createWorkflowGroup(payload).subscribe(() => {
        this.getControlsRefData();
      })
    );
  }

  skipStage(w: any, i?: number) {
    /** Logs for testing */
    console.log('Clicked on SkipStage. Details: ', {
      workflowId: this.templateIdValue,
      taskId: w.value.stage_id,
      stageName: w.value.stage_name,
      eventId: this.ewService.selectedEventId,
      eventName: this.ewService.eventName,
    });

    // const stagesWithNoStageId = this.availableWorkflowStages.filter((s) => {
    //   return !s?.value?.stage_id || s?.value?.stage_id.trim().length == 0;
    // });

    // If stage does not have an Id
    if (!w.value.stage_id || w.value.stage_id.trim().length == 0) {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'Cannot Skip',
          hideCanel: true,
          confirmText: 'Ok',
          message: 'Cannot skip. This stage does not have an Id.',
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        // let onlyOneStageNotSkipped = false;
        // const l = this.availableWorkflowStages.length;
        // const skippedStages = this.availableWorkflowStages.filter((s) => {
        //   return s?.value?.stage_status == 'skipped';
        // });
        // onlyOneStageNotSkipped = l - skippedStages.length == 1;
        // this.onlyOneStageError =
        //   this.availableWorkflowStages.length == 1 || onlyOneStageNotSkipped;
      });

      return;
    }

    // Stage has an Id
    const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to skip this stage?',
        confirmText: 'Yes',
        cancelText: 'No',
      },
    });

    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.getEmailIdsOfActiveStage();
          this.subscription$.add(
            this.ewService
              .updateStageStatus(
                this.templateIdValue,
                w.value.stage_id,
                this.orgService.silentAuthToken,
                'skipped',
                `Stage <b>${w.value.stage_name}<b> is skipped for event <b>${this.ewService.eventName}</b>.`
              )
              .subscribe((resp: any) => {
                if (i != undefined && i > -1) {
                  this.triggerEmailForActiveUsersInNextStage(i);
                }

                this.notificationService.showSuccess(
                  `${w.value.stage_name} skipped successfully.`
                );

                this.getWorkflow(this.templateIdValue!);

                this.ewService.getWorkflowPermissionsForSelectedEvent(
                  this.ewService.getSelectedEventId()
                );
              })
          );
        }
      })
    );
  }

  onDeleteWorkflow() {
    const message = `Are you sure you wish to delete ${this.ewData.template_info.template_name}?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: message,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      },
    });

    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.subscription$.add(
            this.ewService
              .deleteWorkflow(this.ewId, this.orgService.silentAuthToken)
              .subscribe((resp: any) => {
                this.notificationService.showSuccess(
                  `${this.ewData.template_info.template_name} deleted.`
                );
                this.ewService.reload();
                this.closeEditWorkflow();
              })
          );
        }
      })
    );
  }

  logStageStatus(stage_value: any) {
    // console.log(`stage_value: `, stage_value);
  }

  markStageAsCompleteAndSave(i: number) {
    /** Logs for testing */
    console.log('Clicked on MarkStageAsComplete. Details: ', {
      workflowId: this.templateIdValue,
      taskId: this.availableWorkflowStages[i].value.stage_id,
      stageName: this.availableWorkflowStages[i].value.stage_name,
      eventId: this.ewService.selectedEventId,
      eventName: this.ewService.eventName,
    });

    const message = `Are you sure you wish to mark this stage as complete?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Mark Complete',
        message: message,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      },
    });

    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.getEmailIdsOfActiveStage();

          this.availableWorkflowStages[i].patchValue({
            stage_status: 'completed',
          });

          if (this.availableWorkflowStages[i + 1]) {
            this.availableWorkflowStages[i + 1].patchValue({
              stage_status: 'active',
            });
          }

          const stageCompleteMsg = `Stage <b>${this.availableWorkflowStages[i].value.stage_name}</b> is marked complete for event <b>${this.ewService.eventName}</b>.`;
          this.ewService
            .updateStageStatus(
              this.templateIdValue,
              this.availableWorkflowStages[i].value.stage_id,
              this.orgService.silentAuthToken,
              'completed',
              stageCompleteMsg
            )
            .subscribe(() => {
              this.triggerEmailForActiveUsersInNextStage(i);

              this.notificationService.showSuccess(
                `${this.availableWorkflowStages[i].value.stage_name} is marked as complete.`
              );

              this.getWorkflow(this.templateIdValue!);

              this.ewService.getWorkflowPermissionsForSelectedEvent(
                this.ewService.getSelectedEventId()
              );
            });
          // this.onSave();
          // setTimeout(() => this.onSave(), 100);
        }
      })
    );
  }

  checkIfWorkflowComplete(noPatch = false) {
    let completed = true;
    for (let w of this.editWorkflowForm?.get('workflow_stages')?.value) {
      if (
        w?.stage_status == null ||
        w?.stage_status?.trim() == '' ||
        w?.stage_status?.trim() == 'active'
      ) {
        completed = false;
      }
    }
    if (this.editWorkflowForm?.get('workflow_stages')?.value.length == 0) {
      completed = false;
    }

    if (completed == true && noPatch == false) {
      this.editWorkflowForm?.patchValue({
        ...this.editWorkflowForm.value,
        workflow_status: 'completed',
      });
    }

    return completed;
  }

  drop(event: CdkDragDrop<any[]>) {
    const s =
      this.availableWorkflowStages[event.currentIndex].value.stage_status;
    if (s == 'active' || s == 'skipped' || s == 'completed') {
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  statusChangeDisabled() {
    if (
      this.ewService.activeWorkflowId != null &&
      this.templateIdValue?.trim() != this.ewService.activeWorkflowId?.trim()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin(): boolean {
    return this.authorizationService.checkAccess('admin||eventmang');
  }

  adminAccess(status = null) {
    return (
      (this.loggedInUsersRole != 1 && this.loggedInUsersRole != 0) ||
      this.isAdmin()
    );
  }

  editorAccess() {
    // viewer  -> 0
    // editor -> 1
    return this.loggedInUsersRole == 1 || this.isAdmin();
  }

  ngOnDestroy(): void {
    this.newUsersAddedToCurrentWorkflow = [];
    if (this.needsSaving == true) {
      // this.onSave();
      setTimeout(() => {
        this.subscription$.unsubscribe();
      }, 3000);
    } else {
      this.subscription$.unsubscribe();
    }
  }
}
