import { Injectable } from '@angular/core';
import { Offer, OfferDomain, OfferGroupDomain } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { SelectionModel } from '@angular/cdk/collections';

export const OFFERGRP_PAGING = {
  previousPageIndex: 0,
  pageIndex: 0,
  pageSize: 10,
  length: 0,
};

@Injectable({
  providedIn: 'root',
})

export class OfferService<
  TOffer extends Offer,
  TOfferDomain extends OfferDomain<Offer>
> {
  private saveorCancel = new BehaviorSubject<string>('');

  // initialize behavior subjects
  private eventIdBehaviorSubject = new BehaviorSubject<string>('');
  private offerIdBehaviorSubject = new BehaviorSubject<string>('');
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({
    active: 'Name',
    direction: 'asc',
  });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private newVersionBehaviorSubject = new BehaviorSubject<string>('');
  private currentSelectedRecord = new BehaviorSubject<any>({});
  private versionsCount = new BehaviorSubject<number>(0);
  private selectedVersionIds = new BehaviorSubject<any>([]);
  private triggerNewVariant = new BehaviorSubject<any>(null);
  private selectedOfferVersions = new BehaviorSubject<any>(null);
  private offerGPageBehaviorSubject = new BehaviorSubject(OFFERGRP_PAGING);
  private offerGSearchBehaviorSubject = new BehaviorSubject<string>('');
  private offerGSortBehaviorSubject = new BehaviorSubject({
    active: 'Name',
    direction: 'asc',
  });
  private offerGReloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public eventId$ = this.eventIdBehaviorSubject.asObservable();
  public offerId$ = this.offerIdBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public saveorCancel$ = this.saveorCancel.asObservable();
  public newVersion$ = this.newVersionBehaviorSubject.asObservable();
  public selection = new SelectionModel<Offer>(true, []);
  public currentSelectedRecord$ = this.currentSelectedRecord.asObservable();
  public versionsCount$ = this.versionsCount.asObservable();
  public selectedVersionIds$ = this.selectedVersionIds.asObservable();
  public triggerNewVariant$ = this.triggerNewVariant.asObservable();
  public selectedOfferVersions$ = this.selectedOfferVersions.asObservable();
  public dialogueOpened = false;
  public offerGPage$ = this.offerGPageBehaviorSubject.asObservable();
  public offerGSearch$ = this.offerGSearchBehaviorSubject.asObservable();
  public offerGSort$ = this.offerGSortBehaviorSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private organizationService: OrganizationService
  ) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, filters, reload]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: this.eventIdBehaviorSubject.value,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail',
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = params.append(
          '$filter',
          `"${this.buildFilterParam(filters)}"`
        );
      }
      return params;
    })
  );

  public offerGroupParams$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.offerGPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.offerGSortBehaviorSubject,
    this.offerGSearchBehaviorSubject.pipe(debounceTime(300)),
    this.offerGReloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, reload]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: this.eventIdBehaviorSubject.value,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail',
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public offerVersionsparams$ = combineLatest([
    this.offerIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([offerId, page, sort, search, filters, reload]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          key: this.offerIdBehaviorSubject.value,
          // mode: viewMode,
          // $skip: page.pageIndex * page.pageSize,
          // $top: page.pageSize,
          $expand: 'Detail',
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = params.append(
          '$filter',
          `"${this.buildFilterParam(filters)}"`
        );
      }
      return params;
    })
  );

  set newVersion(version: string) {
    this.newVersionBehaviorSubject.next(version);
  }
  set eventId(eventId: string) {
    this.eventIdBehaviorSubject.next(eventId);
  }

  set offerId(offerId: string) {
    this.offerIdBehaviorSubject.next(offerId);
  }

  get SelectedOfferVersionsList() {
    return this.selectedOfferVersions.value;
  }

  set setSelectedOfferVersionsList(value: any) {
    this.selectedOfferVersions.next(value);
  }
  get getNewVersion() {
    return this.newVersionBehaviorSubject.value;
  }

  set addNewVariant(value: any) {
    this.triggerNewVariant.next(value);
  }

  public setSaveorCancel(currentAction: string) {
    this.saveorCancel.next(currentAction);
  }

  set currentRecord(record: any) {
    this.currentSelectedRecord.next(record);
  }

  get getCurrentRecord() {
    return this.currentSelectedRecord.value;
  }

  set versionCount(count: number) {
    this.versionsCount.next(count);
  }

  get getVersionsCount() {
    return this.versionsCount.value;
  }

  set selectedVersionsIds(versionIds: any) {
    this.selectedVersionIds.next(versionIds);
  }

  get selectedVersionsIds() {
    return this.selectedVersionIds.value;
  }

  // get the offers by eventId
  private offersResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GetByEventId`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offer listing
  public offers$: Observable<TOfferDomain[]> = this.offersResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of offer records based on filtering
  public totalRecords$: Observable<number> = this.offersResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // get the offers by eventId
  private offersForOfferGroupsResponse$ = this.offerGroupParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GetByEventId`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offer listing
  public offerGrpOffers$: Observable<TOfferDomain[]> = this.offersForOfferGroupsResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of offer records based on filtering
  public offGrpOfferstotalRecords$: Observable<number> = this.offersForOfferGroupsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  offerGPage(page: any) {
    this.offerGPageBehaviorSubject.next(page);
  }

  // sets the sort property and order
  offerGSort(sort: any) {
    this.offerGSortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  offerGSearch(search: string) {
    const page = this.offerGPageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.offerGSearchBehaviorSubject.next(search);
    this.offerGPageBehaviorSubject.next(page);
  }

  // reloads/refreshes the offer listing
  offerGReload() {
    // reload the Offer data
    this.offerGReloadBehaviorSubject.next(uuidv4());
  }


  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  private offerCategoriesResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferCategories/GetOfferCategories`
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerCategories listing
  public offerCategories$: Observable<TOffer[]> =
    this.offerCategoriesResponse$.pipe(map((res: any) => res.value));

  // gets an offer by id
  getOffer(offerId: string): Observable<OfferDomain<TOffer>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/${offerId}?$expand=Detail`;
    return this.httpClient.get<OfferDomain<TOffer>>(url);
  }

  getBaseClientKey(eventId: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GenerateNewOfferClientKey?eventId=${eventId}`;
    return this.httpClient.get<any>(url);
  }

  getVarintClientKey(eventId: string, offerId: string, variantName: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GenerateNewVariantClientKey?eventId=${eventId}&offerId=${offerId}&variant=${variantName}`;
    return this.httpClient.get<any>(url);
  }

  // gets an offer by id
  getOfferGroup(offerGrpId: string): Observable<OfferGroupDomain<Offer>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferGroupDomains/${offerGrpId}?$expand=Detail`;
    return this.httpClient.get<OfferGroupDomain<Offer>>(url);
  }

  getOfferVersions(offerId: string): Observable<TOffer> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GetOfferVersions?offerId=${offerId}`;
    return this.httpClient.get<TOffer>(url);
  }

  // get the child versions by offerid
  private offerVersionsResponse$ = this.offerVersionsparams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GetOfferDomainVersions`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerpromo listing
  public getofferChildVersions$: Observable<TOfferDomain[]> =
    this.offerVersionsResponse$.pipe(map((res: any) => (res ? res.value : [])));

  // total number of offer records based on filtering
  public totalVersionsRecords$: Observable<number> =
    this.offerVersionsResponse$.pipe(
      map((res: any) => (res ? res['@odata.count'] : 0))
    );

  getAssets() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetBadges`;
    return this.httpClient.get(url);
  }

  getOfferTypes() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTypes`;
    return this.httpClient.get(url);
  }

  getOfferLogs(offerId: string, isPromoLogs = false) {
    let url;

    if (isPromoLogs == true) {
      url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferLog/GetPromoLogsById?promoId=${offerId}`;
    } else {
      url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferLog/GetOfferLogsById?offerId=${offerId}`;
    }

    // Uncomment the following line only for testing
    // const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferLog/GetOfferLogsById?offerId=83c46396-f046-4c14-ba38-cb71559bbded`;

    return this.httpClient.get(url, {
      responseType: 'json',
    });
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the offer listing
  reload() {
    // reload the Offer data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts Offers
  saveOffer(OfferDomain: OfferDomain<TOffer>, eventId: string) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/Post?eventId=${eventId}`;
    return this.httpClient.post(url, OfferDomain);
  }

  // deletes an Offer by id
  deleteOffer(offerId: string, eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/${offerId}?eventId=${eventId}`;
    return this.httpClient.delete(url);
  }

  deleteOfferVersions(offerId: string, eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/DeleteOfferVersions?key=${offerId}&eventId=${eventId}`;
    return this.httpClient.delete(url);
  }

  cloneOffer(sourceId: string, OfferDomain: OfferDomain<TOffer>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/CloneOffer?sourceId=${sourceId}`;
    return this.httpClient.post(url, OfferDomain);
  }

  saveOfferProperties(offerIds: string[], properties: any) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath
      }/${this.organizationService.organization?.version
      }/OfferDomains/UpdateOfferProperties?${this.prepareOfferIdString(
        offerIds
      )}`;
    return this.httpClient.post(url, properties);
  }

  prepareOfferIdString(offerIds: any) {
    let urlParams = '';
    if (offerIds && offerIds.length > 0) {
      for (let index = 0; index <= offerIds.length - 1; index++) {
        urlParams += `offerIds=${offerIds[index]}`;
        if (index != offerIds.length - 1) {
          urlParams += '&';
        }
      }
    }
    return urlParams;
  }

  saveOfferGroupProperties(offerIds: string[], properties: any) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath
      }/${this.organizationService.organization?.version
      }/OfferGroupDomains/UpdateProperties?${this.prepareOfferIdString(
        offerIds
      )}`;
    return this.httpClient.post(url, properties);
  }

  // up-serts Offers
  saveOfferGroup(eventId: string, offerGrp: any) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferGroupDomains?eventId=${this.eventIdBehaviorSubject.value}`;
    return this.httpClient.post(url, offerGrp);
  }

  // changes the view mode of the offer listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach((filter) => {
      if (
        filters.findIndex(
          (item) =>
            item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() &&
            item.value.toLowerCase() === filter.value.toLowerCase()
        ) === -1
      ) {
        filters.push(filter);
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item !== filter
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item.fieldName.toLowerCase() !== fieldName.toLowerCase()
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes all filters for the event listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // builds the filter expressions for filtering the event listing
  private buildFilterParam(filters: Filter[]): string {
    // init the filter expressions
    let expressions = '';

    // build the offerTagId expression
    const offerTagIdFilter = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'offertagid'
    );

    // loop through the division id filters and add filter statement to param
    offerTagIdFilter.forEach((filter, index) => {
      expressions += `OfferTagIds/any(d:contains(d, '${filter.value}'))`;
      // if this index is not the last in the array, add ' or '  else add ')' to param
      if (index !== offerTagIdFilter.length - 1) {
        expressions += ' or ';
      }
    });

    // build the versionIds expression
    const versionIdFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'versionid'
    );

    // if there is an OfferTag filter and we have a Version filter, add ' and (' to expressions
    if (offerTagIdFilter.length > 0 && versionIdFilters.length > 0) {
      expressions += ' and (';
    }

    // loop through the version id filters and add filter statement to param
    versionIdFilters.forEach((filter, index) => {
      expressions += `VersionIds/any(d:contains(d, '${filter.value}'))`;
      // if this index is not the last in the array, add ' or '  else add ')' to param
      if (index !== versionIdFilters.length - 1) {
        expressions += ' or ';
      }
    });

    // if there is an OfferTag filter and we have a Version filter, add ')' to expressions
    if (offerTagIdFilter.length > 0 && versionIdFilters.length > 0) {
      expressions += ')';
    }

    // return the filter expressions
    return expressions;
  }
}
