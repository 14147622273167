import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'src/app/@shared';

@Component({
  selector: 'app-offer-group-edit',
  templateUrl: './offer-group-edit.component.html',
  styleUrls: ['./offer-group-edit.component.scss'],
})
export class OfferGroupEditComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

 
}
