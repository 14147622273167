<!-- page title -->

<div class="offerMainDiv">
  <div class="headerWidth">
    <h1 class="headerMargin">Offer Management</h1>
  </div>


  <!-- <div flex="row" fxLayoutAlign="space-between">
    <button mat-stroked-button title="Previous" style="width: 115px;">
      <fa-icon [icon]="['fas', 'step-backward']"></fa-icon> Previous
    </button>

    <button mat-stroked-button title="Next" style="width: 115px;">Next
      <fa-icon style="padding-left: 9px;" [icon]="['fas', 'step-forward']"></fa-icon>
    </button>

    <button mat-stroked-button type="button" (click)="closeDialog()">
      Close
    </button>
  </div> -->
</div>

<ng-container *ngIf="viewModel$ | async as viewModel">
  <h2 class="event-name">{{viewModel.event.EventName}}
    <link href="#">
  </h2>
</ng-container>

<h2>Create Offer Group</h2>
<p>Filter to begin selecting Offers</p>

<form [formGroup]="formGroup" novalidate>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
    <!-- <mat-form-field appearance="outline" fxFlex="50">
      <mat-label data-testid="Versions">Versions</mat-label>
      <mat-select multiple formControlName="VersionIds">
        <mat-option *ngFor="let version of versionList" [value]="version.Id">
          {{version?.VersionName}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field class="w-45" appearance="outline" (click)="openVersionsDialog()">
      <!-- <input matInput formControlName="offer" readonly /><span><button style="margin-right: 2px;" (click)="openOffersDialog()" mat-button>Edit</button></span> -->
      <input matInput readonly formControlName="version" placeholder="Choose Version" /><span
        style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
    </mat-form-field>

    <mat-form-field class="w-45" appearance="outline" (click)="openOfferTagsDialog()">
      <!-- <input matInput formControlName="offer" readonly /><span><button style="margin-right: 2px;" (click)="openOffersDialog()" mat-button>Edit</button></span> -->
      <input matInput readonly formControlName="offerTags" placeholder="Choose Offer Tag" /><span
        style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
    </mat-form-field>
  </div>
</form>


<div *ngIf="selectedVersionIds">
  <mat-card appearance="outlined">
    <mat-card-content fxLayout="column">
      <ng-container *ngIf="viewModel$ | async as viewModel">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
          <h2>Add Offers</h2>
          <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search Offers</mat-label>
            <input matInput [formControl]="search" type="text" (input)="onSearch($event)" placeholder="Search"
              #searchInput data-testid="Search Bar" />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
              (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px" fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
          <div style="width: 75%;">
            <div class="grid-container">

              <table *ngIf="viewModel.offers.length" mat-table [dataSource]="offersListData" matSort
                (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" class="mat-elevation-z8">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                  <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  <td class="no-ellipsis" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)" [disabled]="row.excluded">
                      <!-- *ngIf="!row.excluded" -->
                    </mat-checkbox>
                  </td>
                </ng-container>

                <!--  Image Column -->
                <ng-container matColumnDef="Image">
                  <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
                    Preview
                  </th>
                  <td mat-cell *matCellDef="let record">
                    <!-- <img src="{{record?.FileName }}"> -->
                    <!-- <img *ngIf="record.FileName" src="{{viewModel.assetsUrl}}/{{record.FileName}}" alt="Offer image">
                  <img *ngIf="!record.FileName" src="./assets/images/default-image.png" alt="Offer image"> -->
                    <ng-container *ngIf="record.FileName">
                      <img mat-card-md-image [src]="getImageSrc(record)" alt="Offer image">
                    </ng-container>
                    <img mat-card-md-image *ngIf="!record.FileName" src="./assets/images/default-image.png"
                      alt="Offer image">
                  </td>
                </ng-container>


                <!-- Id Column -->
                <ng-container matColumnDef="Id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"
                    [resizeColumn]="true">
                    Id
                  </th>
                  <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
                </ng-container>

                <!-- OfferName Column -->
                <ng-container matColumnDef="Name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"
                    [resizeColumn]="true">
                    Offer Name
                  </th>
                  <td mat-cell *matCellDef="let record"> {{ record.Detail.Name }}</td>
                </ng-container>

                <!-- ClientKey Column -->
                <ng-container matColumnDef="ClientKey">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey"
                    [resizeColumn]="true">
                    Client Key
                  </th>
                  <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
                </ng-container>

                <!-- Headline Column -->
                <ng-container matColumnDef="Headline">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline"
                    [resizeColumn]="true">
                    Headline
                  </th>
                  <td mat-cell *matCellDef="let record">{{ record.Detail.Headline }}</td>
                </ng-container>

                <!-- BodyCopy Column -->
                <ng-container matColumnDef="BodyCopy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy"
                    [resizeColumn]="true">
                    BodyCopy
                  </th>
                  <td mat-cell *matCellDef="let record">{{ record.Detail.BodyCopy }}</td>
                </ng-container>

                <!-- Rank Column -->
                <ng-container matColumnDef="Rank">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank"
                    [resizeColumn]="true">
                    Rank
                  </th>
                  <td mat-cell *matCellDef="let record">{{ record.Detail.Rank }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                  (click)="!row.excluded && selection.toggle(row)"
                  [ngStyle]="row.excluded && {'background-color': 'lightgrey'}"></tr>
              </table>

              <table *ngIf="!viewModel.offers.length">
                <tr>
                  <td class="no-records" [attr.colspan]="displayedColumns.length">
                    <h2>No Records Found</h2>
                  </td>
                </tr>
              </table>
            </div>
            <mat-card-actions fxLayout="row" fxLayoutAlign="end">

              <div class="btns" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                <button mat-stroked-button type="button" (click)="onCancel()">
                  Cancel
                </button>
                <button mat-raised-button type="button" color="accent" (click)="saveData(selection.selected)"
                  [disabled]="!selection.selected.length">
                  Add to Group
                </button>
              </div>
            </mat-card-actions>

            <!-- pager -->
            <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
              [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.offers.length"
              showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>
          </div>
          <div>
            <mat-card appearance="outlined" class="card">
              <mat-card-header class="header" color="accent">
                <mat-card-title class="title">New Offer Group</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="noOffers" *ngIf="!offersAdded">
                  <b>Nothing here yet</b>
                  <p class="p">Select Offers and add to group</p>
                </div>

                <div class="grid" *ngIf="offersAdded">
                  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let offers of offerGrpOffers; index as i" cdkDrag>
                      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                      <mat-card>
                        <mat-card-content class="mat-card-content">
                          <div fxLayoutAlign="start center" fxLayout="row wrap" style="margin-top: -17px;">
                            {{i + 1}}
                            <span class="rank">
                              <button mat-icon-button (click)="removeOfferGoup(offers)">
                                <mat-icon>cancel</mat-icon>
                              </button></span>
                            <ng-container *ngIf="offers.FileName">
                              <img class="offer-image" mat-card-md-image [src]="getImageSrc(offers)" alt="Offer image">
                            </ng-container>
                            <img mat-card-md-image *ngIf="!offers.FileName" src="./assets/images/default-image.png"
                              alt="Offer image">
                            <div class="offer-content" fxLayout="column">
                              <div class="offer-headline">
                                <div class="ellipsis-3-line offer-headline-bold">{{ offers.Detail.Headline }}</div>
                              </div>
                              <div class="offer-bodycopy">
                                <div class="ellipsis-3-line">{{ offers.Detail.BodyCopy }}</div>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </mat-card-content>

              <mat-card-actions *ngIf="offersAdded" fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="row" fxLayoutAlign="end center">
                  <button class="fixed-btn" mat-raised-button type="button" color="accent" (click)="saveOfferGroup()">
                    Save Group
                  </button>
                </div>
              </mat-card-actions>

            </mat-card>

          </div>

        </div>


      </ng-container>
    </mat-card-content>
  </mat-card>

</div>




<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>