export const environment = {
  production: false,
  pr1ApiUrl: 'https://pr1dev.przone.net/pr1core/Private',
  appKey: 'promotion engine',
  environment: 'dev',
  appMode: 'standalone',
  commentApiUrl:"https://pr1dev.przone.net/pr1commentapi/Private",
  shopifyAuth: {
    apiKey: '',
    host: '',
  },
  applicationInsights: {
    instrumentationKey: '89111394-0f35-45c3-85ef-3b717c84c902', // development environment
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
  knockApp: {
    FEED_KEY: 'b8a11870-97b0-47a5-a95c-9dee1131200e',
    CLIENT_KEY: 'pk_test_2_pBYKuWjv9a7NC20leDBy5Cfr5p8qG-mDbb3mYGki4',
  },
  auth: {
    domain: 'dev-hn16g194.us.auth0.com',
    clientId: '2BuxUAB3s2SMOzQBOIwG04EZlxDV5cLC',
    audience: 'https://pr1-std-private-api.przone.net',
    organization: 'org_HjNjnLSP4hS3rpdV',
    redirectUri: window.location.origin + '/pr1core/',
  },
  httpInterceptor: {
    allowedList: [`https://pr1dev.przone.net/pr1core/Private/*`,`https://pr1dev.przone.net/pr1commentapi/Private/*`],
  },
  blobStorage: {
    kroger: {
      assetsUrl:
        'https://pr1stddevstandardblob.blob.core.windows.net/public/assets',
    },
    riteaid: {
      assetsUrl:
        'https://pr1stddevstandardblob.blob.core.windows.net/public/assets',
    },
    standard: {
      assetsUrl:
        'https://pr1stddevstandardblob.blob.core.windows.net/public/assets',
    },
    walgreens: {
      assetsUrl:
        'https://pr1stddevstandardblob.blob.core.windows.net/public/assets',
    },
    demo: {
      assetsUrl:
        'https://pr1stddevstandardblob.blob.core.windows.net/public/assets',
    },
    qaautomation: {
      assetsUrl:
        'https://pr1stddevstandardblob.blob.core.windows.net/public/assets',
    },
    unfi: {
      assetsUrl:
        'https://pr1stddevstandardblob.blob.core.windows.net/public/assets',
    },
  },
};
