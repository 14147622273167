import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest } from 'rxjs';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { AuthorizeControlService } from 'pr1-ui-components';
import { OfferAsset, OfferDomain, Offer, Asset, OfferAssetsService, OfferService, OfferAddAssetComponent, AssetsService, OfferPromo, OfferPromoService, PromoDomain } from 'src/app/modules/standard/v1';


const log = new Logger('OfferGroupOffersComponent');

@Component({
  selector: 'app-offer-group-offers',
  templateUrl: './offer-group-offers.component.html',
  styleUrls: ['./offer-group-offers.component.scss'],
})

export class OfferGroupOffersComponent implements OnInit, OnDestroy {

  viewModel$ = combineLatest([
    this.offerAssetService.isLoading$,
    this.offerAssetService.viewMode$,
    this.offerAssetService.offerId$,
    this.offerPromoService.getoffersPromos$,
  ]).pipe(
    map(([isLoading, viewMode, offerId, offers]) => {
      return { isLoading, viewMode, offerId, offers }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  @ViewChild('assetTable', { static: false }) assetTable: any;
  @ViewChild('assetCards', { static: false }) assetCards: any;
  public assetsList: any;
  constructor(private offerAssetService: OfferAssetsService<OfferAsset>,
    private offerService: OfferService<Offer, OfferDomain<Offer>>,
    private offerPromoService: OfferPromoService<OfferPromo, PromoDomain<OfferPromo>>,
    private assetsService: AssetsService<Asset>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizeControlService
    ) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.offerService.eventId = this.route.snapshot.params['eventId'];
    this.offerAssetService.offerId = this.route.snapshot.params['offerId'];
    this.assetsService.offerId = this.route.snapshot.params['offerId'];
    this.offerAssetService.assets$.subscribe((data) => {
      this.assetsList = data;
    });
  }

  ngOnDestroy() { }

  onSearch(event: any) {
    this.offerAssetService.search(event.target.value);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OfferAddAssetComponent, {
      width: '80%',
      height: '90%',
      data: { offerId: this.route.snapshot.params['offerId'] }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.clearSearch();
    })
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.offerAssetService.search('');
  }

  toggleView(mode: string) {
    this.offerAssetService.toggleViewMode(mode);
  }

  cancel(): void {
    this.offerAssetService.reload();
    this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.assetTable) {
      selectedRecords = this.assetTable.getSelectedSectionRecords();
    } else if (this.assetCards) {
      selectedRecords = this.assetCards.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Asset?`,
        },
        disableClose: true,
      });


      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const idstoPass = selectedRecords.map((x: any) => x.Detail.Id);
            const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.offerAssetService.offerId];
            this.offerAssetService.disassociateAssetToOffer(offers , idstoPass).subscribe({
              next: () => {
                this.matSnackBar.open(`Asset(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                if (this.assetTable) {
                  this.assetTable.clearSelection();
                } else if (this.assetCards) {
                  this.assetCards.clearSelection();
                }
                this.clearSearch();
                this.offerAssetService.reload();
                this.router.navigate([`../`, 'assets'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
              },
              error: (error) => {
                log.error('Error deleting asset from offer', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }

  }

  getOfferName(offers: any) {
    if (offers && offers.length > 0) {
      const offerDetail = offers.find((x: PromoDomain<OfferPromo>) => x.DomainId === this.route.snapshot.params['offerId']);
      if (offerDetail && offerDetail.Detail) {
        return offerDetail.Detail.Name;
      } else {
        return '';
      }
    }
    return '';
  }

  
  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    return hasAccess;
  }
}
