import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger, OrganizationService } from 'src/app/@shared';
import {
  EventService,
  Offer,
  Event,
  OfferDomain,
  OfferPromo,
  OfferPromoService,
  PromoDomain,
  CloneOfferComponent,
  VersionService,
  OfferGroupVersionsComponent,
  OfferGroupTagsComponent,
} from '../..';
import { OfferService, Version } from 'src/app/modules/standard/v1';
import { Subscription, combineLatest, distinctUntilChanged, map, take } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit, CdkDragStart, CdkDrag } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
const log = new Logger('OfferGroupComponent');

@Component({
  selector: 'app-offer-group',
  templateUrl: './offer-group.component.html',
  styleUrls: ['./offer-group.component.scss'],
})
export class OfferGroupComponent<
  T extends Offer,
  TOfferDomain extends OfferDomain<Offer>,
  TOfferPromo extends OfferPromo,
  TEvent extends Event,
  TPromoDomain extends PromoDomain<OfferPromo>,
  TVersion extends Version
> implements OnInit, OnDestroy {
  offersListData: MatTableDataSource<any> = new MatTableDataSource();
  viewModel$ = combineLatest([
    this.eventService.getEvent(this.route.snapshot.params['eventId']),
    this.offerService.offerGrpOffers$,
    this.offerService.isLoading$,
    this.offerService.offGrpOfferstotalRecords$,
    this.offerService.offerGPage$,
    this.organizationService.assetsUrl$
  ]).pipe(
    map(
      ([event, offers, isLoading, totalRecords, page, assetsUrl]) => {
        this.assetsUrl = assetsUrl;
        this.offersListData = new MatTableDataSource(offers);
        return {
          event, offers, isLoading, totalRecords, page, assetsUrl
        };
      }
    )
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = [
    'select',
    'Image',
    'Headline',
    'BodyCopy',
    'Rank'
  ];
  versionList: TVersion[] = [];
  eventId: string = '0';
  private assetsUrl: string = '';
  selectedVersionIds: any;
  selectedOfferagIds: any;
  search = new FormControl<string>('');
  offersList: OfferDomain<Offer>[] = [];
  offersAdded = false;
  offerGrpOffers: any = [];

  selection = new SelectionModel<OfferDomain<Offer>>(true, []);
  formGroup = new FormGroup({
    //VersionIds: new FormControl<string[]>([]),
    version: new FormControl([]),
    offerTags: new FormControl([]),
  });
  constructor(
    public route: ActivatedRoute,
    public offerService: OfferService<T, TOfferDomain>,
    private mediaObserver: MediaObserver,
    private router: Router,
    private eventService: EventService<TEvent>,
    private dialog: MatDialog,
    private organizationService: OrganizationService,
    private matSnackBar: MatSnackBar,
  ) {

  }

  ngOnInit(): void {
    log.debug('init');
    this.route.params.subscribe(params => {
      this.eventId = params['eventId'];
    });
    this.offerService.eventId = this.eventId;
    this.offerService.offerGrpOffers$.subscribe((data) => {
      this.offersList = data;
    });
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Headline'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Headline'];
        }
        else {
          this.displayedColumns = ['select', 'Image', 'Headline', 'BodyCopy', 'Rank'];
        }
      });
  }

  // getVersionsList(eventId: string) {
  //   this.versionService.getVersionsForVariants(eventId).subscribe((res) => {
  //     this.versionList = res;
  //   });
  // }

  openVersionsDialog(): void {
    const dialogRef = this.dialog.open(OfferGroupVersionsComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.eventId }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let versionName: any
        if (Array.isArray(res)) {
          versionName = res.map((x: any) => x.VersionName);
        }
        this.formGroup.controls.version.patchValue(versionName);
        this.formGroup.markAllAsTouched();
        if (Array.isArray(res)) {
          this.selectedVersionIds = res.map((x: any) => x.Id);
        }
      }
    });
  }

  openOfferTagsDialog() {
    const dialogRef = this.dialog.open(OfferGroupTagsComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.eventId }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let OfferTagName: any
        if (Array.isArray(res)) {
          OfferTagName = res.map((x: any) => x.OfferTagName);
        }
        this.formGroup.controls.offerTags.patchValue(OfferTagName);
        this.formGroup.markAllAsTouched();
        if (Array.isArray(res)) {
          this.selectedOfferagIds = res.map((x: any) => x.Id);
          console.log('selectedOfferagIds', this.selectedOfferagIds);
        }
      }
    });
  }


  onSearch(event: any) {
    this.offerService.offerGSearch(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.offerService.offerGSearch('');
  }

  onSort(sortState: Sort): void {
    this.offerService.offerGSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.offerService.offerGPage(pageEvent);
  }

  closeDialog() {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.offersList.filter((row: any) => !row.excluded)
      .length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.offersList.forEach((row: any) => { if (!row.excluded) { this.selection.select(row) } });
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  saveData(selectedOffers: any) {
    if (selectedOffers && selectedOffers.length > 0) {
      selectedOffers.forEach((element: any) => {
        element.excluded = true;
      });
    }
    this.offerGrpOffers = [...this.offerGrpOffers, ...selectedOffers];
    this.matSnackBar.open('Offers Added', 'OK', { verticalPosition: 'bottom', panelClass: ['snackbar-error'] })
    this.offersAdded = selectedOffers.length > 0 ? true : false;
    this.selection.clear();
  }

  removeOfferGoup(offer: any) {
    if (this.offerGrpOffers && this.offerGrpOffers.length > 0) {
      this.offerGrpOffers = this.offerGrpOffers.filter((i: any) => i.Detail.Id !== offer.Detail.Id);
      this.offersAdded = this.offerGrpOffers.length > 0 ? true : false;
      if (this.offersListData && this.offersListData.data && this.offersListData.data.length > 0) {
        const currentOffer = this.offersListData.data.find(x => x.Detail.Id === offer.Detail.Id);
        currentOffer.excluded = false;
        //this.offersListData = new MatTableDataSource(offers);
      }
      console.log('OffersR', this.offerGrpOffers)
    }
  }

  validateOfferGrp(row: any) {
    if (this.offerGrpOffers.find((x: any) => x.Detail.Id == row.Detail.Id)) {
      return true
    } else {
      return false;
    }
  }

  saveOfferGroup() {
    let offerIds = this.offerGrpOffers.map((x: any) => x.Detail.Id);
    const dataToSave = {
      EventId: this.eventId,
      OfferTagIds: this.selectedOfferagIds,
      VersionIds: this.selectedVersionIds,
      OfferIds: offerIds
    }
    this.offerService.saveOfferGroup(dataToSave).subscribe((res: any) => {
      console.log('OffGRes',res);
      this.router.navigate([`../${res.Id}/offergroups/offergrpdetails`], { relativeTo: this.route})
    })
  }

  onCancel() {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = this.assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

  ngOnDestroy(): void {
    //   if (this.offerlistModelSub) this.offerlistModelSub.unsubscribe();

    //   this.offerserv.currentRecord = {};
    //   this.offerserv.newVersion = '';
    //   this.offerserv.addNewVariant = null;
    //   this.offerserv.dialogueOpened = false;
    // }

    // setNavigation(): void {
    //   if (this.offersList && this.offersList.length) {
    //     this.offerId = this.route.snapshot.params['offerId'];
    //     if (this.offersList) {
    //       this.currentOfferIndex = this.offersList.findIndex(
    //         (x) => x && x.DomainId === this.offerId
    //       );
    //     }
    //   }
    //   if (this.offerlistModelSub) this.offerlistModelSub.unsubscribe();
  }

  // onSave() {
  //   this.offerserv.setSaveorCancel('1');
  //   this.disableNewVersion = false;
  //   this.offerserv.newVersion = '';
  // }

  // onCancel() {
  //   this.offerserv.setSaveorCancel('0');
  //   this.offerserv.newVersion = '';
  // }

  // reloadCurrentRoute() {
  //   let currentUrl = this.router.url;
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate([currentUrl]);
  //   });
  //   this.getVersions('');
  // }

  // tagSelected(event: MatAutocompleteSelectedEvent) { }

  // navigatetoNewOffer(previous: boolean) {
  //   let idtoOpen;

  //   if (!this.offersList || this.offersList.length <= 0) return;

  //   //let currentIndex = this.currentOfferIndex; //this.offersList.findIndex(x => x.Detail.Id === this.offerId);

  //   if (previous) {
  //     this.currentOfferIndex--;
  //     if (this.offersList[this.currentOfferIndex]) {
  //       idtoOpen = this.offersList[this.currentOfferIndex];
  //     } else {
  //       let pIndex = this.currentOfferIndex + 1 - this.offersListPage.pageSize;
  //       this.loadOfferListPagingData(pIndex, this.offersListPage.pageSize);
  //     }
  //   } else {
  //     this.currentOfferIndex++;
  //     if (this.offersList[this.currentOfferIndex]) {
  //       idtoOpen = this.offersList[this.currentOfferIndex];
  //     } else {
  //       this.loadOfferListPagingData(
  //         this.currentOfferIndex / this.offersListPage.pageSize,
  //         this.offersListPage.pageSize
  //       );
  //     }
  //   }
  //   this.offerId = idtoOpen?.DomainId as string;
  //   this.goToOfferPromo(idtoOpen);

  //   // if (idtoOpen) {
  //   //   let urltoOpen = idtoOpen.EventType === 'PROMO' ? `../${idtoOpen.Detail.Id}/promos/promodetails` : `../${idtoOpen.Detail.Id}/details`
  //   //   if (this.route.snapshot.routeConfig && this.route.snapshot.routeConfig.path === 'promos') {
  //   //     urltoOpen = '../' + urltoOpen;
  //   //   }
  //   //   this.router.navigate([urltoOpen], { relativeTo: this.route }).then(x => {
  //   //     this.reloadCurrentRoute();
  //   //   });

  //   // }
  // }

  // goToOfferPromo(OfferPromo: any, versionName?: string) {
  //   if (OfferPromo) {
  //     this.isPromo = OfferPromo.EventType === 'PROMO';
  //     this.getVersions(versionName as string);
  //     let urltoOpen =
  //       OfferPromo.EventType === 'PROMO'
  //         ? `../${OfferPromo.DomainId}/promos/promodetails`
  //         : `../${OfferPromo.DomainId}/details`;
  //     if (
  //       this.route.snapshot.routeConfig &&
  //       this.route.snapshot.routeConfig.path === 'promos'
  //     ) {
  //       urltoOpen = '../' + urltoOpen;
  //     }
  //     console.log(urltoOpen);
  //     this.router
  //       .navigate([urltoOpen], { relativeTo: this.route })
  //       .then((x) => {
  //         // this.reloadCurrentRoute();
  //       });
  //   }
  // }

  // enableButton(previous: boolean) {
  //   let idtoOpen;
  //   if (!this.offersList || this.offersList.length <= 0) return false;
  //   const currentIndex = this.offersList.findIndex(x => x.Detail.Id === this.offerId);
  //   if (previous) {
  //     if (this.offersList[currentIndex - 1]) {
  //       idtoOpen = this.offersList[currentIndex - 1];
  //     }
  //   } else {
  //     if (this.offersList[currentIndex + 1]) {
  //       idtoOpen = this.offersList[currentIndex + 1];
  //     }
  //   }
  //   if (idtoOpen) {
  //     return true;
  //   }
  //   return false;
  // }

  // getSelectedVersionDetail(event: any) {
  //   if (this.versionsList && this.versionsList.length > 0) {
  //     this.offerserv.newVersion = '';
  //     const versionName = this.versionsList.find(
  //       (x) => x.Id === event.value.Id
  //     );
  //     if (versionName && versionName.Id) {
  //       this.offerId = versionName.Id;
  //       let idtoOpen = {
  //         DomainId: versionName.Id,
  //         EventType: this.isPromo ? 'PROMO' : 'OFFER',
  //       };
  //       this.goToOfferPromo(idtoOpen);
  //     }
  //   }
  //   this.disableNewVersion = false;
  // }

  // setCurrentActiveItem(item: string) {
  //   this.activeNavItem = item;
  // }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.offerGrpOffers, event.previousIndex, event.currentIndex);
  }
}
