

export interface Offer {
    ClientKey?: string;
    Id: string;
    CommentsCount?: number;
    StartDate: Date | string;
    EndDate: Date | string;
    Name: string;
    OfferAsset?: string;
    Headline?: string;
    BodyCopy?: string;
    Disclaimer?: string;
    ClientComment?: string;
    SalePrice?: number;
    Status?: string;
    Rank: number;
    TargetURL: string;
    AdRetailPrice?: number;
    UnitOfMeasure: string;
    Quantity?: number;
    PercentOff?: number;
    DollarOff?: number;
    BuyQuantity?: number;
    GetQuantity?: number;
    Limit?: string;
    WhenYouBuy?: number;
    SaveAmount?: number;
    selected?: boolean;
    BadgeAssetId?: string;
    OfferTypeId?: string;
    OfferVariantName?: string;
    RewardAmount?: number;
    RewardQuantity?: number;
    RewardSpend?: number;
    CustomPricingText?: string;
    MaxSave?: number;
    MinSave?: number;
    AndUp?: string;
    PercentAmount?: number;
    SavePercent?: number;
    SaveStandard?: number;
    SpendAmount?: number;
    AdditionalDiscountAmount: number;
    AdditionalDiscountQuantity: number;
    AdditionalDiscountAmount2: number;
    AdditionalDiscountQuantity2: number;
    AdditionalDiscountAmount3: number;
    AdditionalDiscountQuantity3: number;
    WithLoyaltyMembership: boolean;
    PrintPageId?:string;
    PageNumber?:string;
    Position?:number;

}
